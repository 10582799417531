import styled from "styled-components";

export const PricingTableInner = styled.div`
    position: relative;
    overflow: hidden;
    transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
    text-align: center;
    padding: 40px 20px 0px;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 0 30px rgba(51, 51, 51, 0.1);
`;

export const PricingTableHead = styled.div`
    margin-bottom: 5px;
`;

export const PricingTableTitle = styled.h4`
    font-size: 19px;
    line-height: 1.5;
    color: black;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 5px;
    z-index: 2;
    position: relative;
`;

export const PricingTableSubtitle = styled.h6`
    font-size: 13px;
    line-height: 1.5;
    color: inherit;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 21px;
    z-index: 2;
    position: relative;
    p{
        height: 25px;
    }
`;

export const PricingTableImage = styled.figure`
    position: relative;
    width: 162px;
    height: 162px;
    border-radius: 50%;
    margin: 0 auto 20px;
    background-image: linear-gradient(160deg, #fbf7f4 0%, #fefdfd 100%);
    align-items: center;
    display: flex;
    justify-content: center;
`;

export const PricingTablePrice = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    font-weight: 500;
    sup{
        font-size: 20px;
        margin-bottom: 0;
        color: #ababab;
        top: 10px;
    }
    h6{
        font-size: 56px;
        line-height: .8;
        margin: 0 4px;
        font-weight: 400;
        color: #333;
    }
    sub{
        font-size: 15px;
        margin-bottom: 0;
        color: #ababab;
        align-self: flex-end;
        bottom: 10px;
    }
`;

export const PricingTableBody = styled.div``;

export const PricingTableBtn = styled.div`
    padding-bottom: 30px;
    
    a {
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: 700;
        border-radius: 30px;
        width: 100%;    
    }
    
    a {
        background-color: #F62585;
        border-color: #F62585;
        color: #fff;
    }
    
    a[hover="2"] {
        background-color: #086AD8;
        border-color: #086AD8;  
    }
    
    a[hover="2"]:hover{
        background-color: #F62585;
        border-color: #F62585;
    }
    
    .hidden {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0.5s;
      height: 0px;
    }
    
    .visible {
      visibility: visible;
      opacity: 1;
      padding-top: 30px;
    }
`;

export const PricingTableSetup = styled.div`
    margin-left: -20px;
    margin-right: -20px;
    margin-top: 20px;
    svg {
        stroke-width: 2px;
        font-size: 18px;
        margin-left: 5px;
    }
    button {
        width:100%;
        background-color: #f8f8f8;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 2px;
        color: #086AD8;
        padding: 13px 0px;
        border: none;
    }
    button:hover,
    button.active{
        background-color: #086AD8;
        color: #fff;
    }
    `;

export const PricingTableOldPrice = styled.div `
    p {
        color: #ababab;
        text-decoration: line-through;
        font-size: 30px;
        font-weight: 300;
    }
`;

export const PricingTableSetupPrice = styled.div `
    p {
        margin-top: 10px;
        color: #ababab;
        font-size: 25px;
        font-weight: 300;
    }
    span {
        color: black;
    }
`;

export const PricingTableList = styled.ul`
    margin: 0px auto 0;
    max-width: 270px;
    text-align: left;
    li{
        position: relative;
        display: block;
        padding: 7px 0 7px 0;
        svg{
            color: #fff;
            font-size: 18px;
            margin-right: 10px;
            background-color: rgb(246, 37, 133);
            border-radius: 50%;
            stroke-width: 2px;
            padding: 1px;
        }
    }
    li.feature-crossed{
        position: relative;
        display: block;
        padding: 7px 0 7px 0;
        opacity: 0.4;
        text-decoration: line-through;
        svg{
            color: #fff;
            font-size: 18px;
            margin-right: 10px;
            background-color: rgb(246, 37, 133);
            border-radius: 50%;
            stroke-width: 2px;
            padding: 1px;
        }
    }
`;

export const PricingTableListMore = styled.div`
    .hidden {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0.5s;
      height: 0px;
    }
    ul{
        margin: 0px auto 0;
        max-width: 270px;
        text-align: left;
        li{
            position: relative;
            display: block;
            padding: 7px 0 7px 0;
            svg{
                color: #fff;
                font-size: 18px;
                margin-right: 10px;
                background-color: rgb(246, 37, 133);
                border-radius: 50%;
                stroke-width: 2px;
                padding: 1px;
            }
        }
        li.feature-crossed{
        position: relative;
        display: block;
        padding: 7px 0 7px 0;
        opacity: 0.4;
        text-decoration: line-through;
        svg{
            color: #fff;
            font-size: 18px;
            margin-right: 10px;
            background-color: rgb(246, 37, 133);
            border-radius: 50%;
            stroke-width: 2px;
            padding: 1px;
        }
    }
    }
`;

export const PricingTableWrap = styled.div`
    margin-bottom: 30px;
    &:hover{
        ${PricingTableInner}{
            box-shadow: 0 41px 43px rgba(51, 51, 51, 0.07);
            border: 0 solid transparent;
            transform: translateY(-5px);
        }
    }
`;

export const PricingTableFeatureMark = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    height: 92px;
    width: 92px;
    background: ${props => props.theme.colors.secondary};
    span{
        position: absolute;
        top: 20px;
        right: 7px;
        text-align: center;
        font-size: 11px;
        font-weight: 700;
        line-height: 1.19;
        display: block;
        color: #fff;
        transform: rotate(45deg);
        width: 50px;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
    &:after{
        transform: rotate(-45deg);
        background: none repeat scroll 0 0 #fff;
        content: "";
        height: 159px;
        right: 23px;
        position: absolute;
        top: 10px;
        width: 100%;
    }
`;

export const PricingHiddenDetails = styled.div `
    .hidden {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0.5s;
      height: 0px;
    }
    
    .visible {
      visibility: visible;
      opacity: 1;
    }

    text-align: left;
    
    .hiddenFeatureWrap p {
        display: inline-block;
        width: fit-content;
        margin-bottom: 0px;
        font-size: 14px;
    }
    
    .hiddenFeatureWrap div:nth-child(3) {
        padding-bottom: 10px;
        border-bottom: 2px solid #f8f8f8;
    }
    
    .hiddenFeatureWrap h6 {
        text-transform: uppercase;
    }
    
    .altele h6 {
        display: none;
    }
    
    .hiddenFeatureWrap div:last-child svg {
        color: #fff;
        font-size: 18px;
        margin-right: 10px;
        background-color: rgb(246, 37, 133);
        border-radius: 50%;
        stroke-width: 2px;
        padding: 1px;    
    }
    
    .hiddenFeatureWrap > div:last-child {
        padding-top: 10px;
    }
    
    .hiddenFeatureWrap div:last-child p {
        font-size: 16px;
        padding: 7px 0px;
    }
    
    .hiddenFeatureWrap div:not(last-child) svg {
        stroke-width: 2px;
        margin-right: 2px;
    }
    
    .hiddenFeatureWrap div:not(last-child) {
        padding-bottom: 10px;
    }
    
    .switch {
      position: relative;
      display: inline-block;
      width: 35px;
      height: 20px;
      margin-bottom: 0px;
    }

    .switch input { 
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #f0f0f0;
      -webkit-transition: .4s;
      transition: .4s;
    }
    
    .slider:before {
      position: absolute;
      content: "";
      height: 12px;
      width: 12px;
      left: 4px;
      bottom: 4px;
      background-color: #F62585;
      -webkit-transition: .4s;
      transition: .4s;
    }
    
    input:checked + .slider {
      background-color: #086AD8;
    }
    
    input:checked + .slider:before {
        background-color: #fff;
    }
    
    input:focus + .slider {
      box-shadow: 0 0 1px #2196F3;
    }
    
    input:checked + .slider:before {
      -webkit-transform: translateX(16px);
      -ms-transform: translateX(16px);
      transform: translateX(16px);
    }
    
    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }
    
    .slider.round:before {
      border-radius: 50%;
    }
    
    .switchSection {
        padding-top: 0px;
        padding-bottom: 30px;
    }
    
    .switchRow {
        display: flex;
        align-items: center;
        padding-bottom: 7px;
        min-width: 50%;
    }
    
    .switchSection {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
    } 
    
    .switchRow label {
        margin-right: 6px;
    }
    
    .hiddenFeatureWrap div:not(:last-child) p {
        margin-right: 6px;
    }
    
    a {
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: 700;
        border-radius: 30px;
        width: 100%;    
    }
    
    a {
        background-color: #F62585;
        border-color: #F62585;
        color: #fff;
    }
    
    a[hover="2"] {
        background-color: #086AD8;
        border-color: #086AD8;  
    }
    
    a[hover="2"]:hover{
        background-color: #F62585;
        border-color: #F62585;
    }
    
    .setupPriceLink {
        text-align:center;
    }
  
    p.oldPrice {
        color: #ababab;
        text-decoration: line-through;
        font-size: 30px;
        font-weight: 300;
        margin-bottom: 0px;
    }  
    
    .newPrice {
        display: flex;
        align-items: flex-start;
        font-weight: 500;
        justify-content: center;
    }
    

    .newPrice sup{
        font-size: 20px;
        margin-bottom: 0;
        color: #ababab;
        top: 5px;
        margin-right: -7px;
    }
    
    .newPrice h6{
        font-size: 56px;
        line-height: .8;
        margin: 0 4px;
        margin-bottom: 10px;
        font-weight: 400;
        color: #333;
    }
    
    .setupPriceLink {
        margin-left: -20px;
        margin-right: -20px;
        padding: 20px 20px 30px 20px;
        background: #F8F8F8;
    }
`;

