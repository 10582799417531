import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-one'
import PageHeader from '../components/pageheader'
import PricingTab from '../containers/pricing-plan'
import ContactArea from '../containers/index-appointment/contact-area'
import PricingTableSection from "../containers/elements/pricing-table/section-one";

const PricingPage = ({ pageContext, location }) => (
    <Layout location={location}>
        <SEO title=" The pricing plans of our powerful ecommerce platform"
        description="2 Cart understands that we are different and comes to your aid with several price options. Choose the pricing plan that answers your needs, and start selling with the help of this ecommerce platform."/>
        <Header />
        <PageHeader
            pageContext={pageContext}
            location={location}
            title="Pricing plans"
            subtitle="Transparent. Customer-centric. Scalable."
        />
        <main className="site-wrapper-reveal">
            <PricingTableSection/>
            {/*<PricingTab />*/}
            <ContactArea />
        </main>
        <Footer />
    </Layout>
)

export default PricingPage
