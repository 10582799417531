import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-one'
import PageHeader from '../components/pageheader'
import AboutArea from '../containers/about-us/about-area'
import ServicesArea from '../containers/about-us/services-area'
import ResourcesArea from '../containers/about-us/resources-area'
import SolutionsArea from '../containers/about-us/solutions-area'
import TestimonialArea from '../containers/global/testimonial-area/section-one'
import ContactArea from '../containers/index-appointment/contact-area'
import CTAArea from "../containers/global/cta-area/section-two";


const AboutPage = ({ pageContext, location }) => (
  <Layout location={location}>
    <SEO title="About our creative e-commerce development team"
         description="The 2Cart e-commerce development team always tries to offer fast solutions for modern business. We know the exact recipe for a PWA solution that increases conversions and is user-friendly enough that items don’t lie forgotten in the shopping cart."/>
    <Header />
      <PageHeader
          pageContext={pageContext}
          location={location}
          title="About Us"
          subtitle="Our team in a nutshell"
      />
    <main className="site-wrapper-reveal">

      <AboutArea />
      <ResourcesArea />
      <SolutionsArea />
      <ServicesArea />
      <CTAArea/>
      <TestimonialArea />
      <ContactArea />
    </main>
    <Footer />
  </Layout>
)

export default AboutPage
