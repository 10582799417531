import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from '../../../components/ui/wrapper'
import SectionTitle from '../../../components/ui/section-title'
import Button from '../../../components/ui/button'
import { SectionWrap, JobList, JobItem, JobDesc, JobButton } from './style'
import firstImage from '../../../data/images/awards/thecon.jpg'
import secondImage from '../../../data/images/awards/xsure.svg'
import thirdImage from '../../../data/images/awards/prestamaniacs.jpg'
import forthImage from '../../../data/images/awards/modeltheme.png'

const thecon = "https://thecon.ro/";
const xsure = "https://xsure.io/";
const prestamaniacs = "https://prestamaniacs.com/";
const modeltheme = "https://modeltheme.com/";

const images = [firstImage, secondImage, thirdImage, forthImage];
const partnersURL = [thecon, xsure, prestamaniacs, modeltheme];

const JobsArea = () => {
    const getJobsData = useStaticQuery(graphql`
        query GET_JOBS_DATA {
            intro: careersJson(id: {eq: "careers-section-intro"}) {
                title
            }
            jobs: careersJson(id: {eq: "our-open-jobs"}) {
                jobs {
                    id
                    title
                    type
                    description
                }
            }
        }      
    `);
    const { intro: { title }, jobs: { jobs } } = getJobsData

    return (
        <SectionWrap>
            <Container>
                <Row justify="center">
                    <Col lg={7}>
                        <SectionTitle title={title} />
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <JobList>
                            {jobs.map((job, i) => {
                                const isEven = i % 2 === 0;
                                return (
                                    <JobItem key={job.id} isEven={isEven}>
                                        <Row>
                                            <Col lg={3}>
                                                <img src={images[i]}/>
                                            </Col>
                                            <Col lg={6}>
                                                <JobDesc>{job.description}</JobDesc>
                                            </Col>
                                            <Col style={{display: "flex", flexDirection:"column", justifyContent:"center"}} lg={3}>
                                                <JobButton>
                                                    <Button varient="outlined" bordercolor="#ddd"><a style={{color: "inherit"}} href={partnersURL[i]} target="_blank">See partner</a></Button>
                                                </JobButton>
                                            </Col>
                                        </Row>
                                    </JobItem>
                                )
                            })}
                        </JobList>
                    </Col>
                </Row>
            </Container>
        </SectionWrap>
    )
}

export default JobsArea
