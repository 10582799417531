import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-one'
import PageHeader from '../components/pageheader'
import ContactFormArea from '../containers/contact-us/contact-form-area'
import CTAArea from '../containers/global/cta-area/section-two'


const ContactUsPage = ({ pageContext, location }) => (
    <Layout location={location}>
        <SEO title="A turnkey solution for successful online businesses"
             description="Are you looking for successful online business ideas? 2 Cart may be exactly the solution you need. Contact us to find out more about how you can start an ecommerce business quickly and at an affordable price.
" />
        <Header/>
        <PageHeader 
            pageContext={pageContext} 
            location={location}
            title="Contact Us"
            subtitle="We need each other to change the landscape of e-commerce."
        />
        <main className="site-wrapper-reveal">
            <ContactFormArea/>
            <CTAArea/>
        </main>
        <Footer/>
    </Layout>
  )
   
  export default ContactUsPage
