import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import { MdComment, MdInfoOutline } from "react-icons/md";
import Section, { Container, Row, Col } from '../../../../components/ui/wrapper'
import Heading from '../../../../components/ui/heading'
import Button from '../../../../components/ui/button'
import Background from '../../../../data/images/bg/cta-bg.png'
import {HeroWrapper} from "../../../index-appointment/hero-area/hero-area.style";

const CTASection = ({
    sectionStyle,
    heading,
    text,
    ButtonOne,
    ButtonTwo,
    ...props
}) => {
    const ctaData = useStaticQuery(graphql `
        query CtaTwoImgQuery {
            file(relativePath: {eq: "images/bg/cta-bg.png"}) {
                childImageSharp {
                    fluid( quality: 99) {
                        ...GatsbyImageSharpFluid_withWebp
                        presentationWidth
                        presentationHeight
                    }
                }
                }
            }
         
    `);
    const imageData = ctaData.file.childImageSharp.fluid;
    return (
        <Fragment style={{overflowX: "hidden"}}>
            <div style={{height: "500px", display: "flex", flexDirection: "column", justifyContent: "center", marginTop: "-80px"}}>
        <HeroWrapper
            tag="section"
            fluid={imageData}
            style={{
                width: "100%",
                // minHeight: "357.4px",
                maxHeight: "auto",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                // backgroundColor: "green",
                paddingTop: "124px"

            }}
        >
            <Container style={{display: "flex", flexDirection: "row", height: "70%", justifyContent: "center", }}>
                <Row style={{height: "220%", display: "flex", flexDirection: "column", position: "relative", top: "80px", justifyContent:"center", alignSelf: "center",   }} className="align-items-center text-lg-left text-center">
                    {/*<Col xl={5} lg={4}>*/}
                        <h4 style={{color: "white",textAlign: "center" }} {...heading}>It's as easy as saying 1, 2 Cart. No time to waste. <br/> Ask for a free demo now! </h4>
                    {/*</Col>*/}
                    <div className="text-center">
                        <Button
                            style={{
                                backgroundColor: "#FFFFFF",
                                color: "#0B6CD8",
                                width: "200px"
                            }}
                            {...ButtonOne}
                            icon={<MdComment/>}
                            iconposition="left"
                            icondistance="4px"
                            iconsize="14px">
                            <a style={{color: "inherit" }} target="_blank" rel="noopener noreferrer" href="https://demo.2cart.io/">
                            View DEMO
                            </a>
                        </Button>
                        <Button
                            style={{
                                backgroundColor: "#F62585",
                                color: "#FFFFFF",
                                width: "200px"
                            }}
                            to="/pricing-plan"
                            {...ButtonTwo}
                            icon={<MdInfoOutline/>}
                            iconposition="left"
                            icondistance="4px"
                            iconsize="14px">
                            I want to buy
                        </Button>
                    </div>
                </Row>
            </Container>
        </HeroWrapper>
            </div>
        </Fragment>
    )
}

CTASection.propTypes = {
    heading: PropTypes.object
}

CTASection.defaultProps = {
    sectionStyle: {
        bgColor: '#f6f2ed',
        bgposition: 'top 35% right -68px',
        bgsize: 'auto',
        pt: '80px',
        pb: '80px',
        responsive: {
            medium: {
                pt: '60px',
                pb: '60px'
            },
            small: {
                pt: '40px',
                pb: '40px'
            }
        }
    },
    heading: {
        as: 'h3',
        child: {
            color: 'primary'
        },
        responsive: {
            medium: {
                mb: '10px'
            }
        }
    },
    text: {
        as: 'span',
        color: 'secondary'
    },
    ButtonOne: {
        skin: 'primary',
        m: '7px'
    },
    ButtonTwo: {
        skin: 'secondary',
        m: '7px'
    }
}


export default CTASection
