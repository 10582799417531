import React, { useState } from 'react'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { Row, Col } from '../../ui/wrapper'
import useFormUrl from '../useFormUrl'
import Form, { FormGroup, Input, Textarea, Error } from '../../ui/form'
import Button from '../../ui/button'

const ContactForm = () => {

    // const sentSuccessfully = "Message was sent successfully. Thank you!";
    // const sentFailed = "Failed sendng the message. Please try again later.";

    const formUrl = useFormUrl;
    const { register, handleSubmit, errors, reset } = useForm({
        mode: "onBlur"
    })

    const [serverState, setServerState] = useState({
        submitting: false,
        status: null
    });

    // Connecting to backend START
    const [ nameState, setNameState ] = useState({
        name: "",
    });
    const [ emailState, setEmailState ] = useState({
       email: "",
    });
    const [ subjectState, setSubjectState ] = useState({
        subject: "Message from contact form",
    });
    const [ messageState, setMessageState ] = useState({
        message: "",
    });
    const [ sentState, setSentState ] = useState({
        sent: false,
    });

    const [ sentMessageState, setSentMessageState ] = useState({
        sentMessage: "",
    });


    const handleNameChange = (e) => {
        setNameState({
            name: e.target.value,
        })
    }

    const handleEmailChange = (e) => {
        setEmailState({
            email: e.target.value,
        })
    }
    const handleSubjectChange = (e) => {
        setSubjectState({
            subject: e.target.value,
        })
    }
    const handleMessageChange = (e) => {
        setMessageState({
            message: e.target.value
        })
    }

    const handleSendMail = () => {

        const email = emailState.email;
        const subject = subjectState.subject;
        const text = "2 Cart!\n" +"Message from " +nameState.name +":" +"\n\n\n" +messageState.message;

        const data = {
            email,
            subject,
            text
        };


        axios({
            method: "post",
            url: "https://2cart.io/send_emails.php",
            data: data
        })
            .then(r => {
                setSentState({
                    sent: true
                })
                setSentMessageState({
                    sentMessage: "Message was sent successfully. Thank you!"
                })
            })
            .catch(error => {
                if (!error.response) {
                    // network error
                    console.log('Error: Network Error');
                } else {
                    console.log(error.response.data.message);
                }
                setSentState({
                    sent: true
                });
                setSentMessageState({
                    sentMessage: "Failed sendng the message. Please try again later."
                });
            })
    }

    // Connecting to backend END

    const handleServerResponse = (ok, msg, form) => {
        setServerState({
            submitting: false,
            status: { ok, msg }
        });
        if (ok) {
            form.reset();
        }
    };



    const onSubmit = (data, e) => {
        const form = e.target;
        setServerState({ submitting: true });
        axios({
            method: "post",
            url: formUrl,
            data: data
        })
            .then(r => {
                handleServerResponse(true, "Thanks! for contact with us", form);
            })
            .catch(r => {
                handleServerResponse(false, r.response.data.error, form);
            });
    }
    return (
        <div>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row gutters={{ lg: 20 }}>
                <Col lg={6}>
                    <FormGroup mb="20px">
                        <Input
                            value={nameState.name}
                            onChange={handleNameChange}
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Name *"
                            ref={register({ required: 'Name is required' })}
                        />
                        <Error>{errors.name && errors.name.message}</Error>
                    </FormGroup>
                </Col>
                <Col lg={6}>
                    <FormGroup mb="20px">
                        <Input
                            value={emailState.email}
                            onChange={handleEmailChange}
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Email *"
                            ref={register({
                                required: 'Email is required',
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "invalid email address"
                                }
                            })}
                        />
                        <Error>{errors.email && errors.email.message}</Error>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <FormGroup mb="20px">
                        <Input
                            value={subjectState.subject}
                            onChange={handleSubjectChange}
                            type="text"
                            name="subject"
                            id="subject"
                            placeholder="Subject *"
                            ref={register({ required: 'Subject is required' })}
                        />
                        <Error>{errors.subject && errors.subject.message}</Error>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <FormGroup mb="30px">
                        <Textarea
                            value={messageState.message}
                            onChange={handleMessageChange}
                            name="message"
                            id="message"
                            placeholder="Please describe what you need."
                            ref={register({
                                required: 'Message is required',
                                maxLength: {
                                    value: 2000,
                                    message: "Maximum length is 2000"
                                },
                                minLength: {
                                    value: 10,
                                    message: "Minimum length is 10"
                                }
                            })}
                        ></Textarea>
                        <Error>{errors.message && errors.message.message}</Error>
                    </FormGroup>
                </Col>
            </Row>


        </Form>
            <Row>
                <Col lg={12}>
                    <Button onClick={handleSendMail} disabled={serverState.submitting}>Send Message</Button>
                    {
                        sentState.sent === false ?
                            null :

                            (
                                <div>
                                    <br/>
                                    <p style={sentMessageState.sentMessage === "Failed sendng the message. Please try again later." ? {color: "red"} : {color: "#007aff" }}>
                                        {sentMessageState.sentMessage}
                                    </p>
                                </div>
                    )}
                </Col>
            </Row>
        </div>
    )
}

export default ContactForm;
