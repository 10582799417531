import React from 'react'
import {GridLineWrap} from './grid-line.style'

const GridLine = () => {
    return (
        <GridLineWrap>
            <div className="line line-1"></div>
            <div className="line line-2"></div>
            <div className="line line-3"></div>
            <div className="line line-4"></div>
        </GridLineWrap>
    )
}

export default GridLine
