import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-one'
import HeroArea from '../containers/index-appointment/hero-area'
import ServicesArea from '../containers/index-appointment/services-area'
import TabsArea from '../containers/index-appointment/tabs-area'
import TeamsArea from '../containers/index-appointment/team-area'
import CTAArea from '../containers/global/cta-area/section-two'
import CaseStudyArea from '../containers/index-appointment/case-study-area'
import TestimonialArea from '../containers/global/testimonial-area/section-one'
import FunFactArea from '../containers/global/funfact-area/section-one'
import ContactArea from '../containers/index-appointment/contact-area'
import PricingTableSection from "../containers/elements/pricing-table/section-one";
import CTASection from "../containers/global/cta-area/section-one";

 
const IndexPage = ({location}) => (
  <Layout style={{overflowX: "hidden"}} location={location}>
    <SEO title="An e-commerce solution for your online business"
        description="E-commerce without any hassle. 2 Cart boasts all the benefits of Progressive Web Apps: faster loading process, connectivity independence, progressive enhancement and the list goes on."
    />
    <Header style={{overflowX: "hidden"}}/>
    <main style={{overflowX: "hidden"}} className="site-wrapper-reveal">
      <HeroArea/>
      <ServicesArea />
      <TeamsArea />
      <TabsArea id='test'/>
      <CTAArea />
      <PricingTableSection/>
      <TestimonialArea/>
      <CTASection style={{width:"100vw" }}/>
      <ContactArea/>
    </main>
    <Footer/>
  </Layout>
)

export default IndexPage
