import React, { useState } from 'react';
import axios from 'axios'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import useFormUrl from '../../../components/forms/useFormUrl'
import { Row, Col } from '../../../components/ui/wrapper'
import Form, { FormGroup, Input, Textarea, Error, SelectS, InputS } from '../../../components/ui/form'
import Button from '../../../components/ui/button'



const SurveySection = () => {
    const formItems = [
        {
            id: "name",
            label: "Developer Pack",
            name: "name",
            type: "text"
        }
    ];

    const formUrl = useFormUrl();
    const { register, handleSubmit, errors, reset } = useForm({
        mode: "onBlur"
    });
    const [serverState, setServerState] = useState({
        submitting: false,
        status: null
    });

    // Connecting to backend START
    const [ nameState, setNameState ] = useState({
        name: "",
    });
    const [ emailState, setEmailState ] = useState({
        email: "",
    });
    const [ storeState, setStoreState ] = useState({
        store: "",
    });
    const [ companyState, setCompanyState ] = useState({
        company: "",
    });
    const [ siteState, setSiteState ] = useState({
        site: "",
    });
    const [ onlineState, setOnlineState ] = useState({
        online: "",
    });
    const [ productsState, setProductsState ] = useState({
        products: "",
    });


    const [ sentState, setSentState ] = useState({
        sent: false,
    });

    const [ sentMessageState, setSentMessageState ] = useState({
        sentMessage: "",
    });


    const handleNameChange = (e) => {
        setNameState({
            name: e.target.value,
        })
    }

    const handleEmailChange = (e) => {
        setEmailState({
            email: e.target.value,
        })
    }
    const handleStoreChange = (e) => {
        setStoreState({
            store: e.target.value,
        })
    }
    const handleCompanyChange = (e) => {
        setCompanyState({
            company: e.target.value,
        })
    }
    const handleSiteChange = (e) => {
        setSiteState({
            site: e.target.value,
        })
    }
    const handleOnlineChange = (e) => {
        setOnlineState({
            online: e.target.value,
        })
    }
    const handleProductsChange = (e) => {
        setProductsState({
            products: e.target.value,
        })
    }

    const handleSendMail = () => {
        
        if(nameState.name.length == 0 || emailState.email.length == 0 || companyState.company.length == 0 || storeState.store.length == 0 || productsState.products.length == 0){

            setSentMessageState({
                sentMessage: "Please fill all required fields!"
            });

        } else {

        const save_to_db = 1;
        const name = nameState.name;
        const email = emailState.email;
        const company = companyState.company;
        const store = storeState.store;
        const site = siteState.site;
        const online = onlineState.online;
        const products = productsState.products;

        const data = {
            save_to_db,
            name,
            email,
            company,
            store,
            site,
            online,
            products,
        };


        axios({
            method: "post",
            url: "https://2cart.io/send_emails.php",
            data: data
        })
            .then(r => {
                setSentState({
                    sent: true
                })
                setSentMessageState({
                    sentMessage: "Message was sent successfully. Thank you!"
                })                
            })
            .catch(error => {
                if (!error.response) {
                    // network error
                    console.log('Error: Network Error');
                } else {
                    console.log(error.response.data.message);
                }
                setSentState({
                    sent: true
                });
                setSentMessageState({
                    sentMessage: "Failed sendng the message. Please fill all required fields."
                });
            })
        }
    }


    const handleServerResponse = (ok, msg, form) => {
        setServerState({
            submitting: false,
            status: { ok, msg }
        });
        if (ok) {
            form.reset();
        }
    };
    const onSubmit = (data, e) => {
        const form = e.target;
        setServerState({ submitting: true });
        axios({
            method: "post",
            url: formUrl,
            data: data
        })
            .then(r => {
                handleServerResponse(true, "Thanks! for contact with us", form);
            })
            .catch(r => {
                handleServerResponse(false, r.response.data.error, form);
            });
    }



    return (
        <div>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
            <Col lg={{ span: 6, offset: 3}}>
                <label for="survey_store">What's your name? *</label>
                <InputS
                    value={nameState.name}
                    onChange={handleNameChange}
                    type="text"
                    name="survey_name"
                    id="survey_name"
                    placeholder="Name"
                    ref={register({ required: 'Name is required' })}
                />
                <Error>{errors.survey_name && errors.survey_name.message}</Error>
            </Col>
            </Row>

            <Row>
            <Col lg={{ span: 6, offset: 3}}>
                <label for="survey_store">What's your email address? *</label>
                <InputS
                    value={nameState.email}
                    onChange={handleEmailChange}
                    type="email"
                    name="survey_email"
                    id="survey_email"
                    placeholder="Email"
                    ref={register({
                        required: 'Email is required',
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "invalid email address"
                        }
                    })}
                />
                <Error>{errors.survey_email && errors.survey_email.message}</Error>
            </Col>
            </Row>

            <Row>
            <Col lg={{ span: 6, offset: 3}}>
                <label for="survey_store">Could you tell us what company do you work for?</label>
                <InputS
                    value={nameState.company}
                    onChange={handleCompanyChange}
                    type="text"
                    name="survey_company"
                    id="survey_company"
                    placeholder="Company name"
                />
                <Error>{errors.survey_company && errors.survey_company.message}</Error>
            </Col>
            </Row>

            <Row>
            <Col lg={{ span: 6, offset: 3}}>
            <label for="survey_store">Does you company own an online store? *</label>
            <SelectS
                onChange={handleStoreChange}
                name="survey_store"
                id="survey_store"
                ref={register({ required: 'Field is required' })}
            >
                <option value="">Please select value</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </SelectS>
            <Error>{errors.survey_store && errors.survey_store.message}</Error>
            </Col>
            </Row>

            {storeState.store == 'Yes' && (

            <Row>
            <Col lg={{ span: 6, offset: 3}}>
                <label for="survey_online">What's the address of your online store? *</label>
                <InputS
                    value={nameState.site}
                    onChange={handleSiteChange}
                    type="text"
                    name="survey_site"
                    id="survey_site"
                    placeholder="Website"
                    ref={register({ required: 'Field is required' })}
                />
                <Error>{errors.survey_site && errors.survey_site.message}</Error>
            </Col>
            </Row>

            )}

            {storeState.store == 'No' && (
            <Row>
            <Col lg={{ span: 6, offset: 3}}>
                <label for="survey_online">Does your company plan to open an online store? *</label>
                <SelectS
                    onChange={handleOnlineChange}
                    name="survey_online"
                    id="survey_online"
                    ref={register({ required: 'Field is required' })}
                >
                    <option value="">Please select value</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </SelectS>
                <Error>{errors.survey_online && errors.survey_online.message}</Error>
            </Col>
            </Row>
            )}

            <Row>
            <Col lg={{ span: 6, offset: 3}}>
                <label for="survey_online">How many products does your web store have or will have? *</label>
                <SelectS
                    onChange={handleProductsChange}
                    name="survey_products"
                    id="survey_products"
                    ref={register({ required: 'Field is required' })}
                >
                    <option value="">Please select value</option>
                    <option value="Up to 3.000">Up to 3.000</option>
                    <option value="Up to 5.000">Up to 5.000</option>
                    <option value="Up to 10.000">Up to 10.000</option>
                    <option value="Over 10.000">Over 10.000</option>
                </SelectS>

                <Error>{errors.survey_products && errors.survey_products.message}</Error>
            </Col>
            </Row>

        </Form>
            <Row>
                <Col lg={{ span: 6, offset: 3}}>
                <Button onClick={handleSendMail} disabled={serverState.submitting}>Get brochure</Button>
                {
                    sentState.sent === false ?
                        null :

                        (
                            <div>
                                <br/>
                                <p style={sentMessageState.sentMessage === "Failed sendng the message. Please try again later." ? {color: "red"} : {color: "#007aff" }}>
                                    {sentMessageState.sentMessage}
                                </p>
                            </div>
                        )}
                 {sentState.sent && (
                    <h5>Thank you for answering our questions. <a style={{color: "#F62585"}} href="https://2cart.io/2cart_ebook.pdf" target="_blank" download> Here is your download link.</a></h5>
                )}
                </Col>
            </Row>
           
        </div>
    )
}

export default SurveySection
