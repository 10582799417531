import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-one'
import PageHeader from '../components/pageheader'
import ServicesArea from '../containers/it-services/services-area'
import ContactArea from '../containers/index-appointment/contact-area'
import Timeline from "../components/ui/timeline";
import TimelineArea from "../containers/elements/timeline";

const ITServicePage = ({ pageContext, location }) => (
  <Layout location={location}>
    <SEO title="How to develop an ecommerce business with us"
         description="Do you want to start an ecommerce business but everything seems too complicated? 2 Cart will bring your business idea to life in as few clicks as possible. Maximum performance from us, minimal effort from your part."/>
    <Header/>

    <PageHeader
        pageContext={pageContext}
        location={location}
        title="How it works"
        subtitle="Results-oriented process developed to bring your business idea to life in as few clicks as possible. Maximum performance from us, minimal effort from your part."
    />

      <div style={{marginTop: "60px",display: "flex", flexDirection: "row", justifyContent: "center"}}>
          <h3 style={{width:"60%", textAlign: "center"}}><span style={{color: '#F62585'}}>Create</span> or <span style={{color: "#076AD8" }}>migrate. </span>We support both picks.</h3>
      </div>
      <div style={{marginTop: "30px",display: "flex", flexDirection: "row", justifyContent: "center"}}>
          <p style={{width:"70%", textAlign: "center"}}>Whether you choose to start afresh or to migrate your website, the process remains foolproof. Summarized into a few easy steps, this is how 2Cart gets things done:</p>
      </div>

          <main className="site-wrapper-reveal">
        <TimelineArea/>
        <ContactArea/>
    </main>
    <Footer/>
  </Layout>
)
 
export default ITServicePage
