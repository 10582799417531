import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import { MdComment, MdInfoOutline } from "react-icons/md";
import Section, { Container, Row, Col } from '../../../../components/ui/wrapper'
import Heading from '../../../../components/ui/heading'
import Button from '../../../../components/ui/button'

const CTASection = ({
    sectionStyle,
    heading,
    text,
    ButtonOne,
    ButtonTwo,
    ...props
}) => {
    const ctaData = useStaticQuery(graphql `
        query CtaImgQuery {
            file(relativePath: {eq: "images/bg/cta-bg-2.png"}) {
                childImageSharp {
                    fluid(maxWidth: 1920, maxHeight: 298, quality: 99) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
    const imageData = ctaData.file.childImageSharp.fluid;
    return ( 
        <Section style={{
            width: "100vw",
            height: "298px",
            backgroundColor: "#007bff",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            overflowX: "hidden"
        }}
                 bgImage={imageData}
        >
            <Container>
                <Row className="align-items-center text-lg-left text-center">
                    <Col xl={8} lg={7}>
                        <Heading {...heading}>ADD 2 CART TO YOUR FUTURE PARTNERSHIP</Heading>
                        <h3 style={{color: "white", fontSize: "20px"}}>Join our team of partners and start earning from the very first client. Welcome to the cart side.</h3>
                    </Col> 
                    <Col xl ={4} lg={5} className="text-center">
                        <Button
                            style={{width: "250px"}}
                            {...ButtonOne}
                            iconposition="left"
                            icondistance="4px"
                            iconsize="16px">
                            <a style={{color: "inherit" }} target="_blank" rel="noopener noreferrer" href="https://demo.2cart.io/">
                            Let me see the DEMO
                            </a>
                        </Button> 
                        <Button
                            style={{
                                backgroundColor: "#F62585",
                                width: "250px"
                            }}
                            to="/partners"
                            {...ButtonTwo}
                            iconposition="left"
                            icondistance="4px"
                            iconsize="16px">
                            Become a partner
                        </Button> 
                    </Col> 
                </Row> 
            </Container> 
        </Section>
    )
}

CTASection.propTypes = {
    heading: PropTypes.object
}

CTASection.defaultProps = {
    sectionStyle: {
        bgColor: '#454545',
        bgposition: 'top 35% right -68px',
        bgsize: 'auto',
        pt: '80px',
        pb: '80px',
        responsive: {
            medium: {
                pt: '60px',
                pb: '60px'
            },
            small: {
                pt: '40px',
                pb: '40px'
            }
        }
    },
    heading: {
        as: 'h3',
        color: '#fff',
        child: {
            color: 'secondary'
        },
        responsive: {
            medium: {
                mb: '10px'
            }
        }
    },
    ButtonOne: {
        skin: 'light',
        m: '7px'
    },
    ButtonTwo: {
        skin: 'secondary',
        m: '7px'
    }
}


export default CTASection
