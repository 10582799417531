import React from "react"
import Layout from '../containers/layout/layout'
import SEO from "../components/seo"
import Header from "../containers/layout/header/header-one";
import PageHeader from "../components/pageheader";
import TimelineArea from "../containers/elements/timeline";
import ContactArea from "../containers/index-appointment/contact-area";
import Footer from "../containers/layout/footer/footer-one";
import JobsArea from "../containers/careers/jobs-area";

const Partners = ({pageContext, location}) => (
    <Layout location={location}>
        <SEO title="Partners who support the development of top ecommerce sites"
             description="Take a look at the companies that have decided to join the process of developing top ecommerce sites. We would love to have you on board and make e-commerce more accessible to a variety of international consumers.
" />

        <Header/>

        <PageHeader
            pageContext={pageContext}
            location={location}
            title="Partnerships"
            subtitle="Joint forces accelerate progress."
        />



        <main className="site-wrapper-reveal">


            <JobsArea />
            <ContactArea/>
        </main>
        <Footer/>

    </Layout>
)

export default Partners
