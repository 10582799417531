import React, { useState } from 'react'
import Img from 'gatsby-image'
import Image from '../../../image'
import { MdDone } from "react-icons/md";
import { MdChevronRight } from "react-icons/md"
import Button from '../../button'
import {
    PricingTableWrap,
    PricingTableInner,
    PricingTableHead,
    PricingTableTitle,
    PricingTableImage,
    PricingTablePrice,
    PricingTableBody,
    PricingTableBtn,
    PricingTableSetup,
    PricingTableList,
    PricingTableFeatureMark,
    PricingTableOldPrice,
    PricingHiddenDetails,
    PricingTableListMore
} from './style'

const initialState = {
    active: false,
}

const PricingTable = ({ title, period, image, oldprice, price, isFeatured, path, features, setupBtn, setups, showSetupPlan, featuresDesign, featuresSetup, featuresIntegrations, featuresSeo, featuresContent, featuresManagement, featuresSupport, }) => {

    const btnStyle = {
        varient: !isFeatured ? 'outlined' : 'contained',
        hover: !isFeatured ? "true" : 2
    }
    const gatsbyImg = image && image.childImageSharp;
    const imageSrc = gatsbyImg || image;
    let boxImage;
    if (imageSrc.fixed && typeof imageSrc.fixed !== 'function') {
        boxImage = <Img fixed={imageSrc.fixed} alt="popup video" />;
    } else if (imageSrc.fluid) {
        boxImage = <Image fluid={imageSrc.fluid} alt="popup video" />
    } else {
        boxImage = <img src={imageSrc} alt="popup video" />
    }

    const [isActive, setActive] = useState({
        active: showSetupPlan,
        });

    const handleToggle = () => {
        setActive({
            active: !isActive.active
        })
    }

    const [isChecked, setChecked] = useState({
        checked1: false,
        checked2: false,
        checked3: false,
        checked4: false,
    });

    const handleCheckboxChange = (e) => {
        e.target.id === "v1-1" ?
        setChecked({
            checked1: true,
            checked2: false,
            checked3: false,
            checked4: false
        })
            :

            e.target.id === "v2-1" ?
                setChecked({
                    checked1: false,
                    checked2: true,
                    checked3: false,
                    checked4: false
                })
                :

                e.target.id === "v3-1" ?
                    setChecked({
                        checked1: false,
                        checked2: false,
                        checked3: true,
                        checked4: false
                    })
                    :

                    e.target.id === "v4-1" ?
                        setChecked({
                            checked1: false,
                            checked2: false,
                            checked3: false,
                            checked4: true
                        })
                        :

            setChecked({
                checked1: false,
                checked2: false,
                checked3: false,
                checked4: false
            })
    }




    return (
        <PricingTableWrap>
            <PricingTableInner>
                {isFeatured && (
                    <PricingTableFeatureMark>
                        <span>Popular Choice</span>
                    </PricingTableFeatureMark>
                )}
                <PricingTableHead>
                    {title && <PricingTableTitle>{title}</PricingTableTitle>}
                    {image && (
                        <PricingTableImage>
                            {boxImage}
                        </PricingTableImage>
                    )}
                    {oldprice && (
                        <PricingTableOldPrice>
                            <p>{oldprice}</p>
                        </PricingTableOldPrice>
                    )}
                    {price && (
                        <PricingTablePrice>
                            <sup>€</sup>
                            <h6>{price}</h6>
                            <sub>/month</sub>
                        </PricingTablePrice>
                    )}
                </PricingTableHead>
                <PricingTableBody>
                    {features && (
                        <PricingTableList>
                            {features.map(feature => <li key={feature.id}><MdDone /><span>{ feature.text }</span></li>)}
                        </PricingTableList>
                    )}
                   
                    {setupBtn && (
                        <PricingTableSetup>
                            <button onClick={handleToggle} className={isActive.active === true ? "active" : "notactive"}>Setup plan<MdChevronRight/></button>
                        </PricingTableSetup>
                    )}
                    {path && (
                        <PricingTableBtn>
                            <div className={isActive.active === false ? "visible" : "hidden"}>
                                <Button {...btnStyle} to={path} >Add 2 Cart!</Button>
                            </div>
                        </PricingTableBtn>
                    )}
                    {setups && (
                        <PricingHiddenDetails>
                            <div className={isActive.active === false ? "hidden" : "visible"}>

                                <div className={"switchSection"}>
                                    {
                                        setups.map((setup, i) => <div className={"switchRow"}>
                                                <label className="switch">
                                                    <input id={setup.id} type="radio" name={"version"} onChange={handleCheckboxChange} className={isChecked ? "unchecked" : "checked"}/>
                                                    <span className="slider round"></span></label>
                                                        <span>{setup.title}</span>
                                            </div>)
                                    }
                                   </div>
                                { isChecked.checked1 === true ?
                                    <div>
                                                <div className="hiddenFeatureWrap">

                                                        {
                                                            setups[0].content.map(( content ) =>
                                                                <div className={content.title}>
                                                                    <h6>{content.title}</h6>
                                                                    {content.featuresList.map(el =>
                                                                        <p><MdDone/>{el}</p>
                                                                    )}

                                                                </div>
                                                            )
                                                        }
                                                </div>
                                                <div className="setupPriceLink">
                                                    <p className="oldPrice">{setups[0].oldPrice}</p>
                                                    <div class="newPrice">
                                                        <sup>€</sup>
                                                        <h6>{setups[0].price}</h6>
                                                    </div>
                                                    <p>pentru contractele semnate pana la 31.12.2020</p>
                                                    <Button {...btnStyle} to={setups[0].btnLink} >Add 2 Cart!</Button>
                                                </div>
                                    </div>
                                    :
                                    isChecked.checked2 === true ?
                                        <div>
                                            <div className="hiddenFeatureWrap">
                                                    {
                                                        setups[1].content.map((content) =>
                                                            <div className={content.title}>
                                                                <h6>{content.title}</h6>
                                                                {content.featuresList.map(el =>
                                                                    <p><MdDone/>{el}</p>
                                                                )}

                                                            </div>
                                                        )
                                                    }
                                            </div>
                                            <div className="setupPriceLink">
                                                <p className="oldPrice">{setups[1].oldPrice}</p>
                                                <div className="newPrice">
                                                    <sup>€</sup>
                                                    <h6>{setups[1].price}</h6>
                                                </div>
                                                <p>pentru contractele semnate pana la 31.12.2020</p>
                                                <Button {...btnStyle} to={setups[1].btnLink}>Add 2 Cart!</Button>
                                            </div>
                                        </div>
                                        :
                                        isChecked.checked3 === true ?
                                            <div>
                                                <div className="hiddenFeatureWrap">
                                                        {
                                                            setups[2].content.map((content) =>
                                                                <div className={content.title}>
                                                                    <h6>{content.title}</h6>
                                                                    {content.featuresList.map(el =>
                                                                        <p><MdDone/>{el}</p>
                                                                    )}

                                                                </div>
                                                            )
                                                        }
                                                </div>
                                                <div className="setupPriceLink">
                                                    <p className="oldPrice">{setups[2].oldPrice}</p>
                                                    <div className="newPrice">
                                                        <sup>€</sup>
                                                        <h6>{setups[2].price}</h6>
                                                    </div>
                                                    <p>pentru contractele semnate pana la 31.12.2020</p>
                                                    <Button {...btnStyle} to={setups[2].btnLink}>Add 2 Cart!</Button>
                                                </div>
                                            </div>
                                            :
                                            isChecked.checked4 === true ?
                                                <div>
                                                    <div className="hiddenFeatureWrap">
                                                            {
                                                                setups[3].content.map((content) =>
                                                                    <div className={content.title}>
                                                                        <h6>{content.title}</h6>
                                                                        {content.featuresList.map(el =>
                                                                            <p><MdDone/>{el}</p>
                                                                        )}

                                                                    </div>
                                                                )
                                                            }
                                                    </div>
                                                    <div className="setupPriceLink">
                                                        <p className="oldPrice">{setups[3].oldPrice}</p>
                                                        <div className="newPrice">
                                                            <sup>€</sup>
                                                            <h6>{setups[3].price}</h6>
                                                        </div>
                                                        <p>pentru contractele semnate pana la 31.12.2020</p>
                                                        <Button {...btnStyle} to={setups[3].btnLink}>Add 2 Cart!</Button>
                                                    </div>
                                                </div>
                                                :
                                                null
                                }
                            </div>
                        </PricingHiddenDetails>
                    )}
                </PricingTableBody>
            </PricingTableInner>
        </PricingTableWrap>
    )
}

export default PricingTable
