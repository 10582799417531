import React from 'react'
import PropTypes from 'prop-types'
import {Container, Row, Col} from '../../../components/ui/wrapper'
import Heading from '../../../components/ui/heading'
import Text from '../../../components/ui/text'
import ContactForm from '../../../components/forms/contact-form'
import {ContactFormWrapper, LeftBox} from './contact-form-area.style'

const ContactFormArea = ({headingStyle, textStyle}) => {
    return (
        <ContactFormWrapper>
            <Container>
                <Row alignitems="start">
                    <Col lg={6}>
                        <div>
                        <LeftBox>
                            <Heading {...headingStyle}>Join us now!<br/> We’ll bring the technology and the cookies. <br/><br/> <span>+40 740 47 00 48</span></Heading>
                            <Text {...textStyle}>If you have any questions regarding 2 Cart, or if you just want to say hi, drop us a message and we’ll get back to you in 1, 2... Cart!</Text>
                        </LeftBox>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <ContactForm/>
                    </Col>
                </Row>
            </Container>
        </ContactFormWrapper>
    )
}

ContactFormArea.propTypes = {
    headingStyle: PropTypes.object,
    textStyle: PropTypes.object
}

ContactFormArea.defaultProps = {
    headingStyle: {
        as: "h3",
        position: "relative",
        pl: '34px',
        lineheight: 1.67,
        fontweight: 600,
        child: {
            color: 'primary'
        },
        layout: 'quote'
    },
    textStyle: {
        mt: '15px',
        fontSize: '18px',
        ml: '34px',
        color: '#696969'
    }
}

export default ContactFormArea;
