import React, { useState } from 'react';
import axios from 'axios'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import useFormUrl from '../useFormUrl'
import { Row, Col } from '../../ui/wrapper'
import Form, { FormGroup, Input, Textarea, Error } from '../../ui/form'
import Button from '../../ui/button'

const ConsultForm = ({ btnStyle }) => {

    const formUrl = useFormUrl();
    const { register, handleSubmit, errors, reset } = useForm({
        mode: "onBlur"
    });
    const [serverState, setServerState] = useState({
        submitting: false,
        status: null
    });

    // Connecting to backend START
    const [ nameState, setNameState ] = useState({
        name: "",
    });
    const [ emailState, setEmailState ] = useState({
        email: "",
    });
    const [ subjectState, setSubjectState ] = useState({
        subject: "Message from footer form",
    });
    const [ messageState, setMessageState ] = useState({
        message: "",
    });
    const [ sentState, setSentState ] = useState({
        sent: false,
    });

    const [ sentMessageState, setSentMessageState ] = useState({
        sentMessage: "",
    });


    const handleNameChange = (e) => {
        setNameState({
            name: e.target.value,
        })
        console.log(nameState)
    }

    const handleEmailChange = (e) => {
        setEmailState({
            email: e.target.value,
        })
        console.log(emailState)
    }
    const handleSubjectChange = (e) => {
        setSubjectState({
            subject: e.target.value,
        })
        console.log(subjectState)
    }
    const handleMessageChange = (e) => {
        setMessageState({
            message: e.target.value
        })
        console.log(messageState)
    }

    const handleSendMail = () => {
        console.log(emailState.email + " " +subjectState.subject + " " +messageState.message);

        const email = emailState.email;
        const subject = subjectState.subject;
        const text = "2 Cart!\n" +"Message from " +nameState.name +":" +"\n\n\n" +messageState.message;

        const data = {
            email,
            subject,
            text
        };


        axios({
            method: "post",
            url: "https://2cart.io/send_emails.php",
            data: data
        })
            .then(r => {
                console.log(data);
                setSentState({
                    sent: true
                })
                setSentMessageState({
                    sentMessage: "Message was sent successfully. Thank you!"
                })
            })
            .catch(error => {
                if (!error.response) {
                    // network error
                    console.log('Error: Network Error');
                } else {
                    console.log(error.response.data.message);
                }
                setSentState({
                    sent: true
                });
                setSentMessageState({
                    sentMessage: "Failed sendng the message. Please try again later."
                });
            })
    }


    const handleServerResponse = (ok, msg, form) => {
        setServerState({
            submitting: false,
            status: { ok, msg }
        });
        if (ok) {
            form.reset();
        }
    };
    const onSubmit = (data, e) => {
        const form = e.target;
        setServerState({ submitting: true });
        axios({
            method: "post",
            url: formUrl,
            data: data
        })
            .then(r => {
                handleServerResponse(true, "Thanks! for contact with us", form);
            })
            .catch(r => {
                handleServerResponse(false, r.response.data.error, form);
            });
    }

    return (
        <div>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row gutters={{ lg: 20 }}>
                <Col lg={6}>
                    <FormGroup mb="20px">
                        <Input
                            value={nameState.name}
                            onChange={handleNameChange}
                            type="text"
                            name="consult_name"
                            id="consult_name"
                            placeholder="Name *"
                            ref={register({ required: 'Name is required' })}
                        />
                        <Error>{errors.consult_name && errors.consult_name.message}</Error>
                    </FormGroup>
                </Col>
                <Col lg={6}>
                    <FormGroup mb="20px">
                        <Input
                            value={emailState.email}
                            onChange={handleEmailChange}
                            type="email"
                            name="consult_email"
                            id="consult_email"
                            placeholder="Email *"
                            ref={register({
                                required: 'Email is required',
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "invalid email address"
                                }
                            })}
                        />
                        <Error>{errors.consult_email && errors.consult_email.message}</Error>
                    </FormGroup>
                </Col>
            </Row>
            {/*<Row>*/}
            {/*    <Col lg={12}>*/}
            {/*        <FormGroup mb="20px">*/}
            {/*            <Select name="consult_inquiry" id="consult_inquiry"*/}
            {/*                ref={register({ required: "Please select a inquiry" })}*/}
            {/*            >*/}
            {/*                <option value="">What kind of business are you?</option>*/}
            {/*                <option value="General Information Request">General Information Request</option>*/}
            {/*                <option value="Partner Relations">Partner Relations</option>*/}
            {/*                <option value="Careers">Careers</option>*/}
            {/*                <option value="Software Licencing">Software Licencing</option>*/}
            {/*            </Select>*/}
            {/*            <Error>{errors.consult_inquiry && errors.consult_inquiry.message}</Error>*/}
            {/*        </FormGroup>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            <Row>
                <Col lg={12}>
                    <FormGroup mb="30px">
                        <Textarea
                            value={messageState.message}
                            onChange={handleMessageChange}
                            name="consult_message"
                            id="consult_message"
                            placeholder="How can we make your life easier?"
                            ref={register({
                                required: 'Message is required',
                                maxLength: {
                                    value: 2000,
                                    message: "Maximum length is 2000"
                                },
                                minLength: {
                                    value: 10,
                                    message: "Minimum length is 10"
                                }
                            })}
                        ></Textarea>
                        <Error>{errors.consult_message && errors.consult_message.message}</Error>
                    </FormGroup>
                </Col>
            </Row>
            {/*<Row>*/}
            {/*    <Col lg={12}>*/}
            {/*        <Button type="submit" disabled={serverState.submitting} {...btnStyle}>Get a free consultation</Button>*/}
            {/*        {serverState.status && (*/}
            {/*            <p className={`form-output ${!serverState.status.ok ? "errorMsg" : "success"}`}>*/}
            {/*                {serverState.status.msg}*/}
            {/*            </p>*/}
            {/*        )}*/}
            {/*    </Col>*/}
            {/*</Row>*/}
        </Form>
            <Row>
                <Col lg={12}>
                    <Button onClick={handleSendMail} disabled={serverState.submitting}>Get a free consultation</Button>
                    {
                        sentState.sent === false ?
                            null :

                            (
                                <div>
                                    <br/>
                                    <p style={sentMessageState.sentMessage === "Failed sendng the message. Please try again later." ? {color: "red"} : {color: "#007aff" }}>
                                        {sentMessageState.sentMessage}
                                    </p>
                                </div>
                            )}
                </Col>
            </Row>
        </div>
    )
}

ConsultForm.propTypes = {
    btnStyle: PropTypes.object
}

ConsultForm.defaultProps = {
    btnStyle: {
        hover: 2,
        pl: '54px',
        pr: '54px'
    }
}

export default ConsultForm;
