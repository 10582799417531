import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-one'
import PageHeader from '../components/pageheader'
import ContactArea from '../containers/index-appointment/contact-area'
import SurveySection from "../containers/elements/survey";

const Survey = ({ pageContext, location }) => (
  <Layout location={location}>
    <SEO title="Brochure"
         description="Brochure"/>
    <Header/>

    <PageHeader
        pageContext={pageContext}
        location={location}
        title="Thank you for downloading our brochure on e-Commerce Trends in France, 2020."
        subtitle="But first, let us get to know each other a bit."
    />
        
          <main className="site-wrapper-reveal">
          <div className="container" style={{marginBottom: "60px", marginTop: "60px", fontSize: "16px"}}>
            <SurveySection/>
          </div>
            
        <ContactArea/>

    </main>
    <Footer/>
  </Layout>
)
 
export default Survey