import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-one'
import PageHeader from '../components/pageheader'
import ServicesArea from '../containers/it-services/services-area'
import ContactArea from '../containers/index-appointment/contact-area'

const TermsConditions = ({ pageContext, location }) => (
  <Layout location={location}>
    <SEO title="Terms and Conditions"
         description="Terms and Conditions"/>
    <Header/>

    <PageHeader
        pageContext={pageContext}
        location={location}
        title="Terms and Conditions"
        subtitle="We take your right to privacy seriously."
    />

      <div className="container" style={{marginBottom: "60px", marginTop: "60px", fontSize: "16px"}}>
        <p>
         2 CART  is the provider of the services outlined on this webpage.<br></br>
         The Customer by subscribing to the Service has agreed to the following terms and conditions. 2 CART reserves the right to update this page at any time, effective upon a notification via an email address you have registered with the Service Provider.<br></br>
         2 CART reserves the right to change these terms at any time in the absence of any other agreement in writing between the Customer and the Service Provider.  Any change shall become effective upon a notification via an email address you have registered with or an email address that you have subsequently provided to the Service Provider. It is your obligation to ensure that you have read, understood and agree to any changes in the terms if notified.
        </p>
        <p>
         Please beware that by using the Website or Services in any manner, you are bound by these Terms of Service as well as our Privacy Policy.
        </p>

        <h5 style={{marginBottom: "20px", marginTop: "20px"}}>Fees</h5>

        <p>
         Certain fees are available upon request. All fees are exclusive of all applicable governmental fees, charges, or taxes, including, without limitation, all sales, goods, services, value-added, harmonized, or other taxes (collectively “Taxes”). Quotes provided by 2 CART are valid for 30 days unless otherwise specified.
        </p>


        <h5 style={{marginBottom: "20px", marginTop: "20px"}}>Billing and Payment</h5>

        <p>
         You shall provide 2 CART with accurate and complete billing information including full name, address, state (if applicable), country, zip code, telephone number and valid payment method information. It is your responsibility to inform2 CART in case of any changes that may affect billing.2 CART may suspend your Services during any period in which payment of fees is overdue by at least 14 days. A suspension will not terminate the Terms of Service and/or dismiss you from any obligation and/or liability.
        </p>

        <h5 style={{marginBottom: "20px", marginTop: "20px"}}>Fee Changes</h5>

        <p>
         2 CART, in its sole discretion and at any time, may modify the Subscription fees. Any change in Subscription fees will become effective the first day after the then-current Subscription period ends.
        </p>
        <p>
         2 CART will provide you with at least a 30 days’ notice of any change in Subscription fees. This allows You to cancel Your Subscription before such change becomes effective.
        </p>
        <p>
         The minimum contract term is one year. Unless explicitly agreed otherwise in writing, You may cancel Your Subscription at the end of the Subscription Period by sending an email to: <a href="mailto:contact@2cart.io">contact@2cart.io</a>
        </p>

        <h5 style={{marginBottom: "20px", marginTop: "20px"}}>Refunds</h5>

        <p>
         Except when required by law, paid Subscription fees are non-refundable.
        </p>

        <h5 style={{marginBottom: "20px", marginTop: "20px"}}>Account termination</h5>

        <p>
         If 2 CART terminates or suspends Your Account,2 CART is not liable for any consequential, indirect, incidental, punitive, extraordinary, exemplary or any other type of damages including and without limitation loss of use, business interruptions, loss of data, loss of revenue and/or profits, whether alleged in tort, contract or any other legal theory and whether or not2 CART is aware of such potential or non-potential damages. Without limiting the foregoing,2 CART may explicitly terminate or suspend Your Account upon any breach of these terms and conditions.
        </p>
        <p>
         Upon termination, Your right to use the Service will cease immediately.
        </p>

        <h5 style={{marginBottom: "20px", marginTop: "20px"}}>Third-Party Service Integration</h5>

        <p>
         2 CART uses Third Party Services to provide the necessary hardware, software, networking, storage, and related technology required to run the Services.
        </p>
        

        <h5 style={{marginBottom: "20px", marginTop: "20px"}}>Downtime</h5>

        <p>
         2 CART will use its best endeavours to maintain the reliability and efficiency of the Services subject to unscheduled interruptions to the availability of the Services due to factors beyond the control of the Service Provider - including any actions by the Customer or third parties, including telecommunications providers. Scheduled Maintenance will be undertaken at regular intervals by 2 CART which shall have no effect on the system.
        </p>

        <h5 style={{marginBottom: "20px", marginTop: "20px"}}>Intellectual Property</h5>

        <p>
         2 CART warrants that it owns all applicable rights, title and interest in and to all Intellectual Property Rights embodied in or associated with the Services (including but not limited to any images, photographs, animations, video, audio, music and text incorporated into the Services and any accompanying published materials).
        </p>


      </div>
          <main className="site-wrapper-reveal">
        <ContactArea/>
    </main>
    <Footer/>
  </Layout>
)
 
export default TermsConditions