import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-one'
import PageHeader from '../components/pageheader'
import ServicesArea from '../containers/it-services/services-area'
import ContactArea from '../containers/index-appointment/contact-area'

const PrivacyPolicy = ({ pageContext, location }) => (
  <Layout location={location}>
    <SEO title="Privacy Policy"
         description="Privacy Policy"/>
    <Header/>

    <PageHeader
        pageContext={pageContext}
        location={location}
        title="Privacy Policy"
        subtitle="We take your right to privacy seriously."
    />

      <div className="container" style={{marginBottom: "60px", marginTop: "60px", fontSize: "16px"}}>
        <p>
         Starting May 25, 2018, the Regulation (EU) 2016/679 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data (the “Regulation”) was applied across all the countries of the European Union.
        </p>
        <p>
         As the Service Provider, 2 CART, based in Romania, Galati,  is legally bound to comply with the obligations arising from this Regulation, and, as a result, we consider your right to privacy of paramount importance.Therefore, it is our duty to inform you of the ways in which we process your data.
        </p>

        <h5 style={{marginBottom: "20px", marginTop: "20px"}}>General Terms</h5>

        <p>
         The personal data we hold about you in our capacity as a data controller may include some or all of the following:
        </p>

        <ul style={{marginLeft: "50px", marginBottom: "20px", listStyle: "disc"}}>
          <li>Contact and identity data including username and password, name, billing address, email address and phone number</li>
          <li>Your payment method</li>
          <li>Details of payments made (and received) by you in connection with 2 Cart services </li>
          <li>Technical data concerning your use of 2 Cart services </li>
          <li>Your preferences for the marketing you wish to receive from us and third parties as well</li>
        </ul>


        <h5 style={{marginBottom: "20px", marginTop: "20px"}}>We share your data only in certain circumstances</h5>

        <p>
         Please take into consideration that we might share your personal data with various third parties of the following types:
        </p>

        <ul style={{marginLeft: "50px", marginBottom: "20px", listStyle: "disc"}}>
          <li>payment services providers</li>
          <li>mailing and delivery services</li>
          <li>website hosting providers</li>
          <li>messaging services providers</li>
          <li>social media platforms</li>
          <li>online and offline marketing service providers</li>
          <li>research and profiling services</li>
          <li>identity verification services</li>
        </ul>

        <p>
         We may also share your personal data with others where to do so is mandated by applicable law.
        </p>
        <p>
         If we transfer your personal data outside the European Economic Area (EEA) to a country that does not provide a similar level of legal protection to that provided by the European’s data protection laws, we put in place legally appropriate safeguards to require the protection of your personal data. You can request details of those safeguards by contacting our Information Officer.
        </p>
        <p>
         If we sell our business or assets, your personal data may be provided to the prospective purchaser’s advisers with appropriate legal protections and will be passed to the new owners of the business.
        </p>


        <h5 style={{marginBottom: "20px", marginTop: "20px"}}>We store your data ethically</h5>

        <p>
         We keep your Personal Data, or other data that you provide to us, as long as necessary for the purposes indicated above, or to comply with our legal obligations such as (statutory) retention periods. After expiry of such a retention period, we will destroy all copies containing Personal Data or anonymize such data.
        </p>

        <h5 style={{marginBottom: "20px", marginTop: "20px"}}>We respect your rights</h5>

        <p>
         At any time during your collaboration or usage of 2cart, you can exercise the following rights:
        </p>

        <ul style={{marginLeft: "50px", marginBottom: "20px", listStyle: "disc"}}>
          <li>the right of access to information</li>
          <li>the right to rectification regarding your personal data</li>
          <li>the right to restriction of processing personal data</li>
          <li>the right to erasure of your personal data</li>
          <li>the right to data portability</li>
          <li>the right not to be subject to a decision based solely on automated processing, including profiling</li>
        </ul>

        <p>
         Please note that this privacy policy might be subject to change from time to time. 2 Cart reserves the right to change, update, modify, or remove any part of this Policy.
        </p>
        <p>
         If you require more information regarding your fundamental rights and freedoms, please feel free to contact us at <a href="mailto:contact@2cart.io">contact@2cart.io</a>
        </p>
      </div>
          <main className="site-wrapper-reveal">
        <ContactArea/>
    </main>
    <Footer/>
  </Layout>
)
 
export default PrivacyPolicy