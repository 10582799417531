import React from "react"
import Layout from '../containers/layout/layout'
import SEO from "../components/seo"
import Header from "../containers/layout/header/header-one";
import PageHeader from "../components/pageheader";
import ContactArea from "../containers/index-appointment/contact-area";
import Footer from "../containers/layout/footer/footer-one";
import BoxImage from "../components/box-image/layout-one";

import PWA from "../../src/data/images/about-us/pwa.jpg"
import Elasticsearch from "../../src/data/images/about-us/elasticsearch.jpg"
import Vuejs from "../../src/data/images/about-us/vuejs.jpg"
import Nodejs from "../../src/data/images/about-us/nodejs.jpg"
import PrestaShop from "../../src/data/images/about-us/prestashop.jpg"
import AWS from "../../src/data/images/about-us/aws.jpg"

import {Col, Row} from "../components/ui/wrapper";
import ResourcesArea from "../containers/about-us/resources-area";

const WhatWeOffer = ({location, pageContext}) => (
    <Layout location={location}>
        <SEO title="What we offer for your online store"
             description="The user experience on your online store will change completely if you choose 2 Cart. Take a look at our website to find out all the benefits of our ecommerce solution." />
        <Header/>

        <PageHeader
            pageContext={pageContext}
            location={location}
            title="What we offer"
            subtitle="2 Cart does exactly what it says on the tin"
        />

        <main className="site-wrapper-reveal">

            <div style={{marginTop: "60px", marginBottom: "60px", display: "flex", flexDirection: "row", justifyContent: "center"}}>
                <h3 style={{width:"60%", textAlign: "center"}}>Here is our <span style={{color: "#f62585"}} >ingredients list:</span> </h3>
            </div>
            {/*<div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>*/}
            {/*    <h3 style={{width:"60%", textAlign: "center"}}>for <span style={{color: "#F62585"}}>modern businesses</span></h3>*/}
            {/*</div>*/}

        <Row style={{marginTop: "60px", marginBottom: "60px", width: "70vw", margin:"0 auto"}}>
            <Col style={{marginBottom: "60px"}} lg={4} md={6} className="box-item" >
                <BoxImage
                    imageSrc={PWA}
                    title={"PWA"}
                    desc={"A progressive web app is designed to mimic the behaviour of a mobile app, but not the cost of one. It creates a similar user experience to that of a mobile app, with the added benefit of being available offline." +
                    "In short, a PWA is a website that goes to the gym daily."}
                    path="/"
                />
            </Col>
            <Col style={{marginBottom: "60px"}} lg={4} md={6} className="box-item" >
                <BoxImage
                    imageSrc={Vuejs}
                    title={"Vue.js"}
                    desc={"Vue.js is the bread and butter of this platform’s front end. It is a progressive Javascript framework used to build interfaces. We selected this framework because it facilitates individual loading of the elements on the platform." +
                    "‘Ease’ is our middle name."}
                    path="/"
                />
            </Col>
            <Col style={{marginBottom: "60px"}} lg={4} md={6} className="box-item" >
                <BoxImage
                    imageSrc={Elasticsearch}
                    title={"Elasticsearch"}
                    desc={"As its name suggests, ElasticSearch is a search engine capable of addressing a growing number of use cases. Its supersonic speed makes it the most used search engine, and its popularity makes it the most up-to-date engine.\n" +
                    "Supersonic indeed."}
                    path="/"
                />
            </Col>
            <Col style={{marginBottom: "60px"}} lg={4} md={6} className="box-item" >
                <BoxImage
                    imageSrc={Nodejs}
                    title={"Node.js"}
                    desc={"Node.js is the Javascript commander in charge of the backend for 2 Cart. If traditional environments would only run their scripts on the browser, the good news is that Node.js executes JavaScript code outside a web browser."}
                    path="/"
                />
            </Col>
            <Col style={{marginBottom: "60px"}} lg={4} md={6} className="box-item" >
                <BoxImage
                    imageSrc={AWS}
                    title={"Amazon Web Services"}
                    desc={"AWS is a cloud computing web service that provides on-demand cloud computing platforms.  They offer an alternative to traditional server farms, selling large scale computing capacity more quickly and cheaply. One of its main benefits is its pay-per-use billing system."}
                    path="/"
                />
            </Col>
            <Col style={{marginBottom: "60px"}} lg={4} md={6} className="box-item" >
                <BoxImage
                    imageSrc={PrestaShop}
                    title={"PrestaShop SaaS"}
                    desc={"PrestaShop is an e-commerce software used to create online stores from scratch. Derived from this open-source software, our PrestaShop SaaS combines the benefits of Prestashop with affordability in  order to deliver maximum-target sales."}
                    path="/"
                />
            </Col>
        </Row>
            <ResourcesArea />
            <ContactArea/>
        </main>
        <Footer/>
    </Layout>
)

export default WhatWeOffer
