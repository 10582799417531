import React from 'react'
import { Container, Row, Col } from '../../../components/ui/wrapper'
import Timeline from '../../../components/ui/timeline'
import ImageOne from '../../../data/images/box-image/timeline-image-01.jpg'
import ImageTwo from '../../../data/images/box-image/timeline-image-02.jpg'
import ImageThree from '../../../data/images/box-image/timeline-image-03.jpg'
import ImageFour from '../../../data/images/box-image/timeline-image-04.jpg'
import ImageFive from '../../../data/images/box-image/timeline-image-05.jpg'
import { SectionWrap } from './style'

const TimelineArea = () => {
    const content = [
        {
            year: "Step 1",
            image: ImageOne,
            title: "Establish your needs",
            desc: "We meet, we analyse, we devise. After an initial discussion, we make sure your needs are understood and pinpointed so we can prepare accordingly."
        },
        {
            year: "Step 2",
            image: ImageTwo,
            title: "Brainstorm and plan",
            desc: "The second step is composing our answers to your needs. Taking into consideration every detail of our introductory discussion, our team will work on a strategy for your e-commerce platform, which will then be sent for your approval."
        },
        {
            year: "Step 3",
            image: ImageThree,
            title: "Design and implement the project",
            desc: "The most sizable step by far is the design and implementation aspect; not only is it the most time-consuming, it is also heavily dependent on collaboration between our team and yours. Aided by PWAs, JS frameworks and quicksilver search engines, this is when we ‘furnish’ your online store with performance-enhancing technologies."
        },
        {
            year: "Step 4",
            image: ImageFour,
            title: "Double check every nook and cranny",
            desc: "At this point of the process, there is no room for mishaps or slips. Every pixel is scrutinized and tested, from the design to the UX to the payment process. After the project is greenlit, we offer you clear training on website maintenance."
        },
        {
            year: "Step 5",
            image: ImageFive,
            title: "Deliver the final product",
            desc: "Last but not least, we will deliver and celebrate the final outcome. The keys to your webstore are now in your hands."
        }

    ]
    return (
        <SectionWrap>
            <Container>
                <Row>
                    <Col lg={12}>
                        <Timeline items={content} />
                    </Col>
                </Row>
            </Container>
        </SectionWrap>
    )
}

export default TimelineArea
