import React, { useState } from 'react';
import axios from 'axios'
import { useForm } from 'react-hook-form'
import useFormUrl from '../useFormUrl'
import Form, {FormGroup, Input, Select, Error, Textarea} from '../../ui/form'
import Button from '../../ui/button'

const AppointmentForm = () => {

    const formUrl = useFormUrl;
    const { register, handleSubmit, errors, reset } = useForm({
        mode: "onBlur"
    })
    const [serverState, setServerState] = useState({
        submitting: false,
        status: null
    });


    // Connecting to backend START
    const [ nameState, setNameState ] = useState({
        name: "",
    });
    const [ emailState, setEmailState ] = useState({
        email: "",
    });
    const [ subjectState, setSubjectState ] = useState({
        subject: "Message from homepage form",
    });
    const [ messageState, setMessageState ] = useState({
        message: "",
    });
    const [ sentState, setSentState ] = useState({
        sent: false,
    });

    const [ sentMessageState, setSentMessageState ] = useState({
        sentMessage: "",
    });


    const handleNameChange = (e) => {
        setNameState({
            name: e.target.value,
        })
        console.log(nameState)
    }

    const handleEmailChange = (e) => {
        setEmailState({
            email: e.target.value,
        })
        console.log(emailState)
    }
    const handleSubjectChange = (e) => {
        setSelectedValueState({
            option: e.target.value
        });
        setSubjectState({
            subject: e.target.value,
        });

        console.log(subjectState)
    }
    const handleMessageChange = (e) => {
        setMessageState({
            message: e.target.value
        })
        console.log(messageState)
    }

    const handleSendMail = () => {
        console.log(emailState.email + " " +subjectState.subject + " " +messageState.message);

        const email = emailState.email;
        const subject = subjectState.subject;
        const text = "2 Cart!\n" +"Message from " +nameState.name +":" +"\n\n\n" +messageState.message;

        const data = {
            email,
            subject,
            text
        };


        axios({
            method: "post",
            url: "https://2cart.io/send_emails.php",
            data: data
        })
            .then(r => {
                console.log(data);
                setSentState({
                    sent: true
                })
                setSentMessageState({
                    sentMessage: "Message was sent successfully. Thank you!"
                })
            })
            .catch(error => {
                if (!error.response) {
                    // network error
                    console.log('Error: Network Error');
                } else {
                    console.log(error.response.data.message);
                }
                setSentState({
                    sent: true
                });
                setSentMessageState({
                    sentMessage: "Failed sendng the message. Please try again later."
                });
            })
    }

    const handleServerResponse = (ok, msg, form) => {
        setServerState({
            submitting: false,
            status: { ok, msg }
        });
        if (ok) {
            form.reset();
        }
    };
    const onSubmit = (data, e) => {
        const form = e.target;
        setServerState({ submitting: true });
        axios({
            method: "post",
            url: formUrl,
            data: data
        })
            .then(r => {
                handleServerResponse(true, "Thanks! for contact with us", form);
            })
            .catch(r => {
                handleServerResponse(false, r.response.data.error, form);
            });
    }
    const [selectedValueState, setSelectedValueState] = useState({
        option: ""
    });

    const handleSelectedOption = (event) => {
        setSelectedValueState({
            option: event.target.value
        });
    }

    return (
        <div>
        <Form style={selectedValueState.option !== "Other" ? { height: "360px" } : { height: "510px" }} onSubmit={handleSubmit(onSubmit)}>

            <FormGroup mb="20px">
                <Input
                    value={nameState.name}
                    onChange={handleNameChange}
                    name="appointment_name"
                    id="appointment_name"
                    type="text"
                    placeholder="Name *"
                    hover="2"
                    ref={register({ required: 'Name is required' })}
                />
                <Error>{errors.appointment_name && errors.appointment_name.message}</Error>
            </FormGroup>
            <FormGroup mb="20px">
                <Input
                    value={emailState.email}
                    onChange={handleEmailChange}
                    name="appointment_email"
                    id="appointment_email"
                    type="email"
                    placeholder="Email *"
                    hover="2"
                    ref={register({
                        required: 'Email is required',
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "invalid email address"
                        }
                    })}
                />
                <Error>{errors.appointment_email && errors.appointment_email.message}</Error>
            </FormGroup>
            <FormGroup mb="20px">
                <Select
                    onChange={handleSubjectChange}
                    name="appointment_inquiry"
                    id="appointment_inquiry"
                    hover="2"
                    ref={register({ required: "Please select a inquiry" })}
                    // onChange = {handleSelectedOption}
                    value = {selectedValueState.option}
                >
                    <option selected value="I want to become a partner">I want to become a partner</option>
                    <option value="I want a demo">I want a demo</option>
                    <option value="I want more info about used technologies">I want more info about used technologies</option>
                    <option value="I want to find out more">I want to find out more</option>
                    <option value="I want a tailored discussion">I want a tailored discussion</option>
                    <option value="Other">Other</option>
                </Select>
                {/*<Error>{errors.appointment_inquiry && errors.appointment_inquiry.message}</Error>*/}
            </FormGroup>
            {
                selectedValueState.option === "Other" ?
                    <FormGroup mb="20px">
                        <Textarea
                            value={messageState.message}
                            onChange={handleMessageChange}
                            style={{height: "10px"}}>

                        </Textarea>
                    </FormGroup>
                    :
                    null

            }

            {/*<FormGroup style={{ position: "relative" }} textalign="center">*/}
            {/*    <Button*/}
            {/*        type="submit"*/}
            {/*        pl="54px"*/}
            {/*        pr="54px"*/}
            {/*        disabled={serverState.submitting}*/}
            {/*        hover="2"*/}
            {/*    >Submit</Button>*/}
            {/*    {serverState.status && (*/}
            {/*        <p className={`form-output ${!serverState.status.ok ? "errorMsg" : "success"}`}>*/}
            {/*            {serverState.status.msg}*/}
            {/*        </p>*/}
            {/*    )}*/}
            {/*</FormGroup>*/}
        </Form>
    <div style={{display: "flex", flexDirection: "column", alignContent: "center", marginTop: "-80px"}}>
            <Button onClick={handleSendMail} disabled={serverState.submitting}>Submit</Button>
            {
                sentState.sent === false ?
                    null :

                    (
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "10px"}}>
                            <br/>
                            <p style={sentMessageState.sentMessage === "Failed sendng the message. Please try again later." ? {color: "red"} : {color: "#007aff" }}>
                                {sentMessageState.sentMessage}
                            </p>
                        </div>
                    )}
  </div>
    </div>
    )
}


export default AppointmentForm;
