import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-one'
import PageHeader from '../components/pageheader'
import ServicesArea from '../containers/it-services/services-area'
import ContactArea from '../containers/index-appointment/contact-area'

const CookiePolicy = ({ pageContext, location }) => (
  <Layout location={location}>
    <SEO title="Cookie policy"
         description="Cookie policy"/>
    <Header/>

    <PageHeader
        pageContext={pageContext}
        location={location}
        title="Cookie policy"
        subtitle=""
    />

      <div style={{marginTop: "60px",display: "flex", flexDirection: "row", justifyContent: "center"}}>
          <h3 style={{width:"60%", textAlign: "center"}}>Cookies</h3>
      </div>
      <div className="container" style={{marginBottom: "60px", marginTop: "60px", fontSize: "16px"}}>
        <p>
         www.2cart.io website uses cookies, which are stored on the user’s hard disk and contain information about the respective user. A cookie is a small data file that is sent to your device when you first visit a website. Cookies usually include an identification number that is unique to the device you are using. Such identifier can help us better understand our Users and how they are using the Site and the Services.
        </p>
        <p>
         Cookies also enable recognition of a User when they re-visit the Site, keeping their settings and preferences and ability to offer customized features. If you prefer, however, at any time you can reset your browser so it refuses all cookies, or notifies you when a cookie is being sent.
        </p>
        <p>
         The Services may implement the following types of cookies: (i) cookies implemented by us for the purposes described above (“First Party Cookie”); and (ii) third party cookies which are set by other online services who run content on the page you are viewing, for example by third party analytics companies who monitor and analyze our web access.
        </p>
        <p>
         You may remove the cookies by following the instructions of your device preferences; however, if you choose to disable cookies, some features of our Services may not operate properly and your online experience may be limited.
        </p>
      </div>
          <main className="site-wrapper-reveal">
        <ContactArea/>
    </main>
    <Footer/>
  </Layout>
)
 
export default CookiePolicy