import React, {useState} from 'react'
import { Container, Row, Col } from '../../../../components/ui/wrapper'
import imageOne from '../../../../data/images/icons/icon-29-90x90.png'
import imageTwo from '../../../../data/images/icons/icon-30-90x90.png'
import imageThree from '../../../../data/images/icons/icon-31-90x90.png'
import imageFour from '../../../../data/images/icons/Pachet_4.png'
import SectionTitle from '../../../../components/ui/section-title'
import { SectionWrap } from './style'




import { MdDone } from "react-icons/md";
import { MdClose } from "react-icons/md";
import { MdChevronRight } from "react-icons/md"
import Button from '../../../../components/ui/button'
import {
    PricingTableWrap,
    PricingTableInner,
    PricingTableHead,
    PricingTableTitle,
    PricingTableImage,
    PricingTablePrice,
    PricingTableBody,
    PricingTableBtn,
    PricingTableSetup,
    PricingTableList,
    PricingTableFeatureMark,
    PricingTableOldPrice,
    PricingHiddenDetails,
    PricingTableListMore,
    PricingTableSubtitle,
    PricingTableSetupPrice
} from '../../../../components/ui/pricing-table/layout-one/style'
import addClasses from "swiper/src/components/core/classes/addClasses";




const PricingTableSection = () => {
    const pricingItems = [
        {
            id: "pricing-one",
            title: "Developer Pack",
            subtitle: "up to 3000 products",
            image: imageOne,
            //oldprice:495,
            price: 450,
            setup: 3500,
            path: "https://my.2cart.io/cart.php?a=add&pid=4&configoption[2]=2&skipconfig=4",
            setupBtn: "Setup plan",
            // features: [
            //     {
            //         id: "basic-feature-1",
            //         text: "Template Front-End"
            //     },
            //     {
            //         id: "basic-feature-2",
            //         text: "Server Architecture"
            //     },
            //     {
            //         id: "basic-feature-3",
            //         text: "Mobile-First Interface"
            //     },
            //     {
            //         id: "basic-feature-4",
            //         text: "Light & data-friendly PWA Template"
            //     }
            // ],
            featuresDesign: [
                {
                    id: "basic-feature-1",
                    text: "Template Front-End",
                    exists: true
                }
            ],
            featuresSetup: [
                {
                    id: "basic-feature-2",
                    text: "Server Architecture",
                    exists: true
                },
                {
                    id: "basic-feature-5",
                    text: "Staging Server",
                    exists: false
                },
                {
                    id: "basic-feature-3",
                    text: "Mobile-First Interface",
                    exists: true
                },
                {
                    id: "basic-feature-4",
                    text: "Light & data-friendly PWA Template",
                    exists: true
                },
                {
                    id: "more-feature-14",
                    text: "Push Notification",
                    exists: false
                },
                {
                    id: "more-feature-15",
                    text: "Installation on home screen",
                    exists: false
                }
            ],
            featuresIntegrations: [
                {
                    id: "more-feature-1",
                    text: "Paypal and Stripe",
                    exists: true
                },
                {
                    id: "more-feature-2",
                    text: "DHL, UPS",
                    exists: true
                },
                {
                    id: "more-feature-10",
                    text: "Social Media Integration",
                    exists: false
                },
                {
                    id: "more-feature-16",
                    text: "Amazon, Ebay and Etsy Marketplaces",
                    exists: false
                },
                {
                    id: "more-feature-21",
                    text: "ERP Integration",
                    exists: false
                },
                {
                    id: "more-feature-17",
                    text: "Mailchimp",
                    exists: false
                },
                {
                    id: "more-feature-18",
                    text: "Hotjar",
                    exists: false
                },
                {
                    id: "more-feature-19",
                    text: "User.com",
                    exists: false
                },
                {
                    id: "more-feature-20",
                    text: "Klaviyo",
                    exists: false
                },
                {
                    id: "more-feature-11",
                    text: "Google Shopping",
                    exists: false
                },
                {
                    id: "more-feature-4",
                    text: "Google Analitycs",
                    exists: true
                },
                {
                    id: "more-feature-12",
                    text: "Google Tag Manager",
                    exists: false
                },
                {
                    id: "more-feature-13",
                    text: "Advanced stats on sales and marketing",
                    exists: false
                }
            ],
            featuresSeo: [
                {
                    id: "more-feature-3",
                    text: "Meta titles & descriptions, Integrated Schema.org",
                    exists: true
                }
            ],
            featuresContent: [
                {
                    id: "more-feature-24",
                    text: "Provided content migration",
                    exists: false
                }
            ],
            featuresManagement: [
                {
                    id: "more-feature-5",
                    text: "Manage products and content",
                    exists: true
                },
                {
                    id: "more-feature-6",
                    text: "Special offers management",
                    exists: true
                },
                {
                    id: "more-feature-7",
                    text: "Orders management",
                    exists: true
                },
                {
                    id: "more-feature-8",
                    text: "Customers management",
                    exists: true
                }
            ],
            featuresSupport: [
                {
                    id: "more-feature-9",
                    text: "by E-mail",
                    exists: true
                },
                {
                    id: "more-feature-23",
                    text: "Dedicated Project Manager",
                    exists: false
                }
            ],
        },
        {
            id: "pricing-two",
            title: "Startup Pack",
            subtitle: "up to 5000 products",
            image: imageOne,
            //oldprice:825,
            price: 750,
            setup: 4500,
            is_featured: true,
            path: "https://my.2cart.io/cart.php?a=add&pid=5&configoption[2]=2&skipconfig=5",
            setupBtn: "Setup plan",
            featuresDesign: [
                {
                    id: "basic-feature-1",
                    text: "Template Front-End",
                    exists: true
                }
            ],
            featuresSetup: [
                {
                    id: "basic-feature-2",
                    text: "Server Architecture",
                    exists: true
                },
                {
                    id: "basic-feature-5",
                    text: "Staging Server",
                    exists: false
                },
                {
                    id: "basic-feature-3",
                    text: "Mobile-First Interface",
                    exists: true
                },
                {
                    id: "basic-feature-4",
                    text: "Light & data-friendly PWA Template",
                    exists: true
                },
                {
                    id: "more-feature-14",
                    text: "Push Notification",
                    exists: false
                },
                {
                    id: "more-feature-15",
                    text: "Installation on home screen",
                    exists: false
                }
            ],
            featuresIntegrations: [
                {
                    id: "more-feature-1",
                    text: "Paypal and Stripe",
                    exists: true
                },
                {
                    id: "more-feature-2",
                    text: "DHL, UPS, FedEx, DPD, TNT",
                    exists: true
                },
                {
                    id: "more-feature-10",
                    text: "Social Media Integration",
                    exists: true
                },
                {
                    id: "more-feature-16",
                    text: "Amazon, Ebay and Etsy Marketplaces",
                    exists: false
                },
                {
                    id: "more-feature-21",
                    text: "ERP Integration",
                    exists: false
                },
                {
                    id: "more-feature-17",
                    text: "Mailchimp",
                    exists: false
                },
                {
                    id: "more-feature-18",
                    text: "Hotjar",
                    exists: false
                },
                {
                    id: "more-feature-19",
                    text: "User.com",
                    exists: false
                },
                {
                    id: "more-feature-20",
                    text: "Klaviyo",
                    exists: false
                },
                {
                    id: "more-feature-11",
                    text: "Google Shopping",
                    exists: true
                },
                {
                    id: "more-feature-4",
                    text: "Google Analitycs",
                    exists: true
                },
                {
                    id: "more-feature-12",
                    text: "Google Tag Manager",
                    exists: true
                },
                {
                    id: "more-feature-13",
                    text: "Advanced stats on sales and marketing",
                    exists: true
                }
            ],
            featuresSeo: [
                {
                    id: "more-feature-3",
                    text: "Meta titles & descriptions, Integrated Schema.org",
                    exists: true
                }
            ],
            featuresContent: [
                {
                    id: "more-feature-24",
                    text: "Provided content migration",
                    exists: false
                }
            ],
            featuresManagement: [
                {
                    id: "more-feature-5",
                    text: "Manage products and content",
                    exists: true
                },
                {
                    id: "more-feature-6",
                    text: "Special offers management",
                    exists: true
                },
                {
                    id: "more-feature-7",
                    text: "Orders management",
                    exists: true
                },
                {
                    id: "more-feature-8",
                    text: "Customers management",
                    exists: true
                }
            ],
            featuresSupport: [
                {
                    id: "more-feature-9",
                    text: "by E-mail",
                    exists: true
                },
                {
                    id: "more-feature-23",
                    text: "Dedicated Project Manager",
                    exists: false
                }
            ],
        },
        {
            id: "pricing-three",
            title: "Business Pack",
            subtitle: "up to 10000 products",
            image: imageTwo,
            // is_featured: true,
            //oldprice:1265,
            price: 1150,
            setup: 5500,
            path: "https://my.2cart.io/cart.php?a=add&pid=6&configoption[2]=2&skipconfig=6",
            setupBtn: "Setup plan",
            featuresDesign: [
                {
                    id: "basic-feature-1",
                    text: "Template Front-End",
                    exists: true
                }
            ],
            featuresSetup: [
                {
                    id: "basic-feature-2",
                    text: "Server Architecture",
                    exists: true
                },
                {
                    id: "basic-feature-5",
                    text: "Staging Server",
                    exists: true
                },
                {
                    id: "basic-feature-3",
                    text: "Mobile-First Interface",
                    exists: true
                },
                {
                    id: "basic-feature-4",
                    text: "Light & data-friendly PWA Template",
                    exists: true
                },
                {
                    id: "more-feature-14",
                    text: "Push Notification",
                    exists: true
                },
                {
                    id: "more-feature-15",
                    text: "Installation on home screen",
                    exists: true
                }
            ],
            featuresIntegrations: [
                {
                    id: "more-feature-1",
                    text: "Paypal and Stripe",
                    exists: true
                },
                {
                    id: "more-feature-2",
                    text: "DHL, UPS, FedEx, DPD, TNT",
                    exists: true
                },
                {
                    id: "more-feature-10",
                    text: "Social Media Integration",
                    exists: true
                },
                {
                    id: "more-feature-16",
                    text: "Amazon",
                    exists: true
                },
                {
                    id: "more-feature-21",
                    text: "ERP Integration",
                    exists: false
                },
                {
                    id: "more-feature-17",
                    text: "Mailchimp",
                    exists: true
                },
                {
                    id: "more-feature-18",
                    text: "Hotjar",
                    exists: true
                },
                {
                    id: "more-feature-19",
                    text: "User.com",
                    exists: true
                },
                {
                    id: "more-feature-20",
                    text: "Klaviyo",
                    exists: true
                },
                {
                    id: "more-feature-11",
                    text: "Google Shopping",
                    exists: true
                },
                {
                    id: "more-feature-4",
                    text: "Google Analitycs",
                    exists: true
                },
                {
                    id: "more-feature-12",
                    text: "Google Tag Manager",
                    exists: true
                },
                {
                    id: "more-feature-13",
                    text: "Advanced stats on sales and marketing",
                    exists: true
                }
            ],
            featuresSeo: [
                {
                    id: "more-feature-3",
                    text: "Meta titles & descriptions, Integrated Schema.org",
                    exists: true
                }
            ],
            featuresContent: [
                {
                    id: "more-feature-24",
                    text: "Provided content migration",
                    exists: false
                }
            ],
            featuresManagement: [
                {
                    id: "more-feature-5",
                    text: "Manage products and content",
                    exists: true
                },
                {
                    id: "more-feature-6",
                    text: "Special offers management",
                    exists: true
                },
                {
                    id: "more-feature-7",
                    text: "Orders management",
                    exists: true
                },
                {
                    id: "more-feature-8",
                    text: "Customers management",
                    exists: true
                }
            ],
            featuresSupport: [
                {
                    id: "more-feature-9",
                    text: "by E-mail",
                    exists: true
                },
                {
                    id: "more-feature-23",
                    text: "Dedicated Project Manager",
                    exists: false
                }
            ]
        },
        {
            id: "pricing-four",
            title: "Enterprise",
            subtitle: " ",
            image: imageThree,
            oldprice: "",
            price: "GET A QUOTE",
            path: "/partners#contact-form",
            setupBtn: "Setup plan",
            featuresDesign: [
                {
                    id: "basic-feature-1",
                    text: "Template Front-End",
                    exists: true
                }
            ],
            featuresSetup: [
                {
                    id: "basic-feature-2",
                    text: "Server Architecture",
                    exists: true
                },
                {
                    id: "basic-feature-5",
                    text: "Staging Server",
                    exists: true
                },
                {
                    id: "basic-feature-3",
                    text: "Mobile-First Interface",
                    exists: true
                },
                {
                    id: "basic-feature-4",
                    text: "Light & data-friendly PWA Template",
                    exists: true
                },
                {
                    id: "more-feature-14",
                    text: "Push Notification",
                    exists: true
                },
                {
                    id: "more-feature-15",
                    text: "Installation on home screen",
                    exists: true
                }
            ],
            featuresIntegrations: [
                {
                    id: "more-feature-1",
                    text: "Paypal, Stripe, Skrill",
                    exists: true
                },
                {
                    id: "more-feature-2",
                    text: "DHL, UPS, FedEx, DPD, TNT",
                    exists: true
                },
                {
                    id: "more-feature-10",
                    text: "Social Media Integration",
                    exists: true
                },
                {
                    id: "more-feature-16",
                    text: "Amazon, Ebay and Etsy Marketplaces",
                    exists: true
                },
                {
                    id: "more-feature-21",
                    text: "ERP Integration",
                    exists: true
                },
                {
                    id: "more-feature-17",
                    text: "Mailchimp",
                    exists: true
                },
                {
                    id: "more-feature-18",
                    text: "Hotjar",
                    exists: true
                },
                {
                    id: "more-feature-19",
                    text: "User.com",
                    exists: true
                },
                {
                    id: "more-feature-20",
                    text: "Klaviyo",
                    exists: true
                },
                {
                    id: "more-feature-11",
                    text: "Google Shopping",
                    exists: true
                },
                {
                    id: "more-feature-4",
                    text: "Google Analitycs",
                    exists: true
                },
                {
                    id: "more-feature-12",
                    text: "Google Tag Manager",
                    exists: true
                },
                {
                    id: "more-feature-13",
                    text: "Advanced stats on sales and marketing",
                    exists: true
                }
            ],
            featuresSeo: [
                {
                    id: "more-feature-3",
                    text: "Meta titles & descriptions, Integrated Schema.org",
                    exists: true
                }
            ],
            featuresContent: [
                {
                    id: "more-feature-24",
                    text: "Provided content migration",
                    exists: true
                }
            ],
            featuresManagement: [
                {
                    id: "more-feature-5",
                    text: "Manage products and content",
                    exists: true
                },
                {
                    id: "more-feature-6",
                    text: "Special offers management",
                    exists: true
                },
                {
                    id: "more-feature-7",
                    text: "Orders management",
                    exists: true
                },
                {
                    id: "more-feature-8",
                    text: "Customers management",
                    exists: true
                }
            ],
            featuresSupport: [
                {
                    id: "more-feature-9",
                    text: "by E-mail",
                    exists: true
                },
                {
                    id: "more-feature-23",
                    text: "Dedicated Project Manager",
                    exists: true
                }
            ]
        }
    ];


    const btnStyle = {
        varient: !pricingItems.is_featured ? 'outlined' : 'contained',
        hover: !pricingItems.is_featured ? "true" : 2
    }
    const gatsbyImg = pricingItems.image && pricingItems.image.childImageSharp;
    const imageSrc = '../../../../data/images/icons/icon-29-90x90.png';
    let boxImage;
    boxImage = [ <img src={imageOne} alt="popup video" />, <img src={imageTwo} alt="popup video" />, <img src={imageThree} alt="popup video" />, <img src={imageFour} alt="popup video" /> ]

    const [isActive, setActive] = useState({
        active: [false, false, false, false]
    });

    const handleToggle = (i) => {
        i === 0 ?
        setActive({
            active: [!isActive.active[i], false, false, false]
        })
            :
            i === 1 ?
                setActive({
                    active: [false, !isActive.active[i], false, false]
                })
                :
                i === 2 ?
                    setActive({
                        active: [false, false, !isActive.active[i], false]
                    })
                    :
                    i === 3 ?
                        setActive({
                            active: [false, false, false, !isActive.active[i]]
                        })
                        :
                        setActive({
                            active: [false, false, false, false]
                        })
    }

    // const setMoreActive = {
    //     isActive = true;
    // }

    // const setMoreActive = (i) => {
    //     setActive({
    //         active: !isActive.active
    //     })
    // }

    // const [isChecked, setChecked] = useState({
    //     checked1: false,
    //     checked2: false,
    //     checked3: false,
    //     checked4: false,
    // });

    // const handleCheckboxChange = (e) => {
    //     e.target.id === "v1-1" ?
    //         setChecked({
    //             checked1: true,
    //             checked2: false,
    //             checked3: false,
    //             checked4: false
    //         })
    //         :

    //         e.target.id === "v2-1" ?
    //             setChecked({
    //                 checked1: false,
    //                 checked2: true,
    //                 checked3: false,
    //                 checked4: false
    //             })
    //             :

    //             e.target.id === "v3-1" ?
    //                 setChecked({
    //                     checked1: false,
    //                     checked2: false,
    //                     checked3: true,
    //                     checked4: false
    //                 })
    //                 :

    //                 e.target.id === "v4-1" ?
    //                     setChecked({
    //                         checked1: false,
    //                         checked2: false,
    //                         checked3: false,
    //                         checked4: true
    //                     })
    //                     :

    //                     setChecked({
    //                         checked1: false,
    //                         checked2: false,
    //                         checked3: false,
    //                         checked4: false
    //                     })
    // }



    return (
        <SectionWrap style={{overflowX: "hidden"}}>
            <Container>
                <Row>
                    <Col lg={12}>
                        <SectionTitle
                            mb="45px"
                            title="There is no one-size-fits-all solution with <span>2 Cart</span> <br/> Pick the <span style='color: #F62585'>pricing plan</span> that answers your needs:"
                            subtitle="Necessities differ, and so do our pricing plans"
                        />
                    </Col>
                </Row>
                <Row>
                    {pricingItems.map((pricing, i) => (
                        <Col lg={3} md={6} key={pricing.id}>
                            {/*<PricingTable*/}
                            {/*    title={pricing.title}*/}
                            {/*    image={pricing.image}*/}
                            {/*    oldprice={pricing.oldprice}*/}
                            {/*    price={pricing.price}*/}
                            {/*    path={pricing.path}*/}
                            {/*    features={pricing.features}*/}
                            {/*    setupBtn={pricing.setupBtn}*/}
                            {/*    isFeatured={pricing.is_featured}*/}
                            {/*    setups={pricing.setups}*/}
                            {/*    showSetupPlan={isActive.active[0] === true ? setActive({*/}
                            {/*        active: [true, false, false, false]*/}
                            {/*    })*/}
                            {/*    :*/}
                            {/*        isActive.active[1] === true ? setActive({*/}
                            {/*                active: [false, true, false, false]*/}
                            {/*            })*/}
                            {/*            :*/}
                            {/*            isActive.active[2] === true ? setActive({*/}
                            {/*                    active: [false, false, true, false]*/}
                            {/*                })*/}
                            {/*                :*/}
                            {/*                isActive.active[3] === true ? setActive({*/}
                            {/*                        active: [false, false, false, true]*/}
                            {/*                    })*/}
                            {/*                    :*/}
                            {/*                    isActive.active[i]*/}
                            {/*    }*/}
                            {/*/>*/}
                            <PricingTableWrap >
                                <PricingTableInner >
                                    {pricing.is_featured && (
                                        <PricingTableFeatureMark>
                                            <span>Popular Choice</span>
                                        </PricingTableFeatureMark>
                                    )}
                                    <PricingTableHead >
                                        {pricing.title && <PricingTableTitle>{pricing.title}</PricingTableTitle>}
                                        {pricing.subtitle && <PricingTableSubtitle>{pricing.subtitle}{pricing.price === "GET A QUOTE" ? <p style={{height: "0px"}}></p> : ""}</PricingTableSubtitle>}
                                        {pricing.image && (
                                            <PricingTableImage >
                                                {boxImage[i]}
                                            </PricingTableImage>
                                        )}
                                        {pricing.oldprice && (
                                            <PricingTableOldPrice style={{height: "50px"}}>
                                                <p>{pricing.oldprice}</p>
                                            </PricingTableOldPrice>
                                        )}
                                        {pricing.price && (
                                            pricing.price === "GET A QUOTE" ?
                                                <PricingTablePrice style={{height: "115px", margin: "39px 0px 0px"}} >
                                                    {/*<sup>€</sup>*/}
                                                    <h6>{pricing.price}</h6>
                                                    {/*<sub>/month</sub>*/}
                                                </PricingTablePrice>
                                                :
                                            <PricingTablePrice style={{height: "50px"}}>
                                                <sup>€</sup>
                                                <h6>{pricing.price}</h6>
                                                <sub>/month</sub>
                                            </PricingTablePrice>
                                        )}
                                        {pricing.setup && (
                                            <PricingTableSetupPrice style={{height: "50px"}}>
                                                <p>Setup fee: <span><sup>€</sup>{pricing.setup}</span></p>
                                            </PricingTableSetupPrice>
                                        )}
                                    </PricingTableHead>
                                    <PricingTableBody >
                                        <p style={{marginBottom: "0px", color: "black", fontWeight: "600"}}>Design</p>
                                        {pricing.featuresDesign && (
                                            <PricingTableList>
                                                {pricing.featuresDesign.map(feature => <li key={feature.id}><MdDone /><span>{ feature.text }</span></li>)}
                                            </PricingTableList>
                                        )}

                                        <p style={{marginBottom: "0px", color: "black", fontWeight: "600"}}>Initial Setup</p>
                                        {pricing.featuresSetup && (
                                            <PricingTableList style={{minHeight: "230px"}}>
                                                {pricing.featuresSetup.map(feature => <li key={feature.id} className={(feature.exists ? 'feature-show' : 'feature-crossed')}>{(feature.exists ? <MdDone /> : <MdClose />)}<span>{ feature.text }</span></li>)}
                                            </PricingTableList>
                                        )}

                                        {pricing.setupBtn && (
                                            <PricingTableSetup style={{marginBottom: "30px"}}>
                                                <button onClick={() => handleToggle(i)} className={isActive.active[i] === true ? "active" : "notactive"}>{isActive.active[i] === true ? "Show less" : "Show more"}<MdChevronRight/></button>
                                            </PricingTableSetup>
                                        )}
                                        
                                        
                                        {pricing.featuresIntegrations && (
                                            <PricingTableListMore>
                                                   <div className={isActive.active[i] === false ? "hidden" : "visible"}>
                                                   <p style={{marginBottom: "0px", color: "black", fontWeight: "600"}}>Integrations</p>
                                                    <ul>
                                                        {pricing.featuresIntegrations.map(feature => <li key={feature.id} className={(feature.exists ? 'feature-show' : 'feature-crossed')}>{(feature.exists ? <MdDone /> : <MdClose />)}<span>{ feature.text }</span></li>)}
                                                    </ul>
                                                     </div>
                                                
                                            </PricingTableListMore>
                                        )}

                                        {pricing.featuresSeo && (
                                            <PricingTableListMore>
                                                   <div className={isActive.active[i] === false ? "hidden" : "visible"}>
                                                   <p style={{marginBottom: "0px", color: "black", fontWeight: "600"}}>SEO on-page</p>
                                                    <ul>
                                                        {pricing.featuresSeo.map(feature => <li key={feature.id} className={(feature.exists ? 'feature-show' : 'feature-crossed')}>{(feature.exists ? <MdDone /> : <MdClose />)}<span>{ feature.text }</span></li>)}
                                                    </ul>
                                                     </div>
                                                
                                            </PricingTableListMore>
                                        )}

                                        {pricing.featuresContent && (
                                            <PricingTableListMore>
                                                   <div className={isActive.active[i] === false ? "hidden" : "visible"}>
                                                   <p style={{marginBottom: "0px", color: "black", fontWeight: "600"}}>Content</p>
                                                    <ul>
                                                        {pricing.featuresContent.map(feature => <li key={feature.id} className={(feature.exists ? 'feature-show' : 'feature-crossed')}>{(feature.exists ? <MdDone /> : <MdClose />)}<span>{ feature.text }</span></li>)}
                                                    </ul>
                                                     </div>
                                                
                                            </PricingTableListMore>
                                        )}

                                        {pricing.featuresManagement && (
                                            <PricingTableListMore>
                                                   <div className={isActive.active[i] === false ? "hidden" : "visible"}>
                                                   <p style={{marginBottom: "0px", color: "black", fontWeight: "600"}}>Management</p>
                                                    <ul>
                                                        {pricing.featuresManagement.map(feature => <li key={feature.id} className={(feature.exists ? 'feature-show' : 'feature-crossed')}>{(feature.exists ? <MdDone /> : <MdClose />)}<span>{ feature.text }</span></li>)}
                                                    </ul>
                                                     </div>
                                                
                                            </PricingTableListMore>
                                        )}

                                        {pricing.featuresSupport && (
                                            <PricingTableListMore>
                                                   <div className={isActive.active[i] === false ? "hidden" : "visible"}>
                                                   <p style={{marginBottom: "0px", color: "black", fontWeight: "600"}}>Support</p>
                                                    <ul>
                                                        {pricing.featuresSupport.map(feature => <li key={feature.id} className={(feature.exists ? 'feature-show' : 'feature-crossed')}>{(feature.exists ? <MdDone /> : <MdClose />)}<span>{ feature.text }</span></li>)}
                                                    </ul>
                                                     </div>
                                                
                                            </PricingTableListMore>
                                        )}
                                       

                                        
                                        
                                        {pricing.path && (
                                            pricing.path === "/partners#contact-form" ?
                                                <PricingTableBtn>
                                                    
                                                        <Button {...btnStyle} to={pricing.path} >Get a quote</Button>
                                                    
                                                </PricingTableBtn>
                                            :
                                            <PricingTableBtn>
                                                
                                                    <Button {...btnStyle} to={pricing.path} >Add 2 Cart</Button>
                                                
                                            </PricingTableBtn>
                                        )}
                                        {/*{pricing.setups && (
                                            <PricingHiddenDetails>
                                                <div className={isActive.active[i] === false ? "hidden" : "visible"}>

                                                    <div className={"switchSection"}>
                                                        {
                                                            pricing.setups.map((setup, i) => <div className={"switchRow"}>
                                                                <label className="switch">
                                                                    <input id={setup.id} type="radio" name={"version"} onChange={handleCheckboxChange} className={isChecked ? "unchecked" : "checked"}/>
                                                                    <span className="slider round"></span></label>
                                                                <span>{setup.title}</span>
                                                            </div>)
                                                        }
                                                    </div>
                                                    { isChecked.checked1 === true ?
                                                        <div>
                                                            <div className="hiddenFeatureWrap">

                                                                {
                                                                    pricing.setups[0].content.map(( content ) =>
                                                                        <div className={content.title}>
                                                                            <h6>{content.title}</h6>
                                                                            {content.featuresList.map(el =>
                                                                                <p><MdDone/>{el}</p>
                                                                            )}

                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                            <div className="setupPriceLink">
                                                                <p className="oldPrice">{pricing.setups[0].oldPrice}</p>
                                                                <div class="newPrice">
                                                                    <sup>€</sup>
                                                                    <h6>{pricing.setups[0].price}</h6>
                                                                </div>
                                                                <p>for contracts signed until 31.12.2020</p>
                                                                <Button {...btnStyle} to={pricing.setups[0].btnLink} >Add 2 Cart</Button>
                                                            </div>
                                                        </div>
                                                        :
                                                        isChecked.checked2 === true ?
                                                            <div>
                                                                <div className="hiddenFeatureWrap">
                                                                    {
                                                                        pricing.setups[1].content.map((content) =>
                                                                            <div className={content.title}>
                                                                                <h6>{content.title}</h6>
                                                                                {content.featuresList.map(el =>
                                                                                    <p><MdDone/>{el}</p>
                                                                                )}

                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                                <div className="setupPriceLink">
                                                                    <p className="oldPrice">{pricing.setups[1].oldPrice}</p>
                                                                    <div className="newPrice">
                                                                        <sup>€</sup>
                                                                        <h6>{pricing.setups[1].price}</h6>
                                                                    </div>
                                                                    <p>for contracts signed until 31.12.2020</p>
                                                                    <Button {...btnStyle} to={pricing.setups[1].btnLink}>Add 2 Cart</Button>
                                                                </div>
                                                            </div>
                                                            :
                                                            isChecked.checked3 === true ?
                                                                <div>
                                                                    <div className="hiddenFeatureWrap">
                                                                        {
                                                                            pricing.setups[2].content.map((content) =>
                                                                                <div className={content.title}>
                                                                                    <h6>{content.title}</h6>
                                                                                    {content.featuresList.map(el =>
                                                                                        <p><MdDone/>{el}</p>
                                                                                    )}

                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div className="setupPriceLink">
                                                                        <p className="oldPrice">{pricing.setups[2].oldPrice}</p>
                                                                        <div className="newPrice">
                                                                            <sup>€</sup>
                                                                            <h6>{pricing.setups[2].price}</h6>
                                                                        </div>
                                                                        <p>for contracts signed until 31.12.2020</p>
                                                                        <Button {...btnStyle} to={pricing.setups[2].btnLink}>Add 2 Cart</Button>
                                                                    </div>
                                                                </div>
                                                                :
                                                                isChecked.checked4 === true ?
                                                                    <div>
                                                                        <div className="hiddenFeatureWrap">
                                                                            {
                                                                                pricing.setups[3].content.map((content) =>
                                                                                    <div className={content.title}>
                                                                                        <h6>{content.title}</h6>
                                                                                        {content.featuresList.map(el =>
                                                                                            <p><MdDone/>{el}</p>
                                                                                        )}

                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <div className="setupPriceLink">
                                                                            <p className="oldPrice">{pricing.setups[3].oldPrice}</p>
                                                                            <div className="newPrice">
                                                                                {/*<sup>€</sup>*/}
                                                                                {/*<h6>{pricing.setups[3].price}</h6>
                                                                            </div>
                                                                            <p>for contracts signed until 31.12.2020</p>
                                                                            <Button {...btnStyle} to={pricing.setups[3].btnLink}>Get a quote</Button>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    null
                                                    }
                                                </div>
                                            </PricingHiddenDetails>
                                        )}*/}
                                    </PricingTableBody>
                                </PricingTableInner>
                            </PricingTableWrap>
                        </Col>
                    ))}
                </Row>
            </Container>
        </SectionWrap>
    )
}

export default PricingTableSection
