// HERE AICI

import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import {PageHeaderWrap} from './page-header.style';

const PageHeader = ({pageContext, location, title, subtitle}) => {
    const { breadcrumb: { crumbs } } = pageContext;
    const disableLinks = [
        "/elements", 
        "/category", 
        "/profile", 
        "/date", 
        "/tag", 
        "/page", 
        "/blog", 
        "/blog/page", 
        "/blogs",
        "/services"
    ]
    const customCrumbLabel = location.pathname.toLowerCase();
    const crumbLabelArr = customCrumbLabel.split('/');
    const label = crumbLabelArr[crumbLabelArr.length - 1]
    const labelArr = label.split('-');
    const imageData = useStaticQuery(graphql `
        query breadCrumbImgQuery {
            file(relativePath: {eq: "images/bg/title-bar-01-bg.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 1920, maxHeight: 400, quality: 99) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
      
    `);
    return (
        <PageHeaderWrap fluid={imageData.file.childImageSharp.fluid}>
            <h1 style={{color: "#FFFFFF"}}>{title}</h1>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
            <p style={{fontSize: "21px", textAlign: "center", width: "50%", color: "#FFFFFF"}} >{subtitle}</p>
            </div>
        </PageHeaderWrap>
    )
}

export default PageHeader
