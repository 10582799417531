import React from 'react';
import PropTypes from 'prop-types'
import ReactVivus from 'react-vivus';
import { truncateString } from '../../../utils/utilFunctions'
import {
    BoxIconWrap,
    BoxIconInner,
    BoxIconImg,
    BoxIconContent,
    BoxIcontitle,
    BoxIconText,
} from './box-icon.style'
import Image from "../../image";
import {
    TeamMemberImage,
} from "../../team-member/team-member.style";

const BoxIcon = (props) => {
    const { id, icon, title, desc, path, imageSrc, name, infoStyle, nameStyle, ...restProps } = props;
    const { boxStyles, titleStyles, textStyles, buttonStyles } = restProps;
    const iconUrl = (icon && icon.svg && icon.svg.publicURL) || icon;

    let teamImg;
    if (imageSrc) {
        teamImg = <Image fluid={imageSrc.fluid} alt={name || 'Team Member'} />
    } else {
        teamImg = <img src={imageSrc} alt={name ||'Team Member'} />
    }
    return (
        <BoxIconWrap style={{width: "300px"}}>
            <BoxIconInner {...boxStyles}>
                <TeamMemberImage>
                    {teamImg}
                    {/*{social && (*/}
                    {/*    <TeamMemberSocialWrap>*/}
                    {/*        <Social {...socialStyle}>*/}
                    {/*            /!*{facebook && <SocialLink title="Facebook" path={facebook}><TiSocialFacebook /></SocialLink>}*!/*/}
                    {/*            /!*{twitter && <SocialLink title="Twitter" path={twitter}><TiSocialTwitter /></SocialLink>}*!/*/}
                    {/*            /!*{instagram && <SocialLink title="Instagram" path={instagram}><TiSocialInstagram /></SocialLink>}*!/*/}
                    {/*        </Social>*/}
                    {/*    </TeamMemberSocialWrap> */}
                    {/*)}*/}
                </TeamMemberImage>
                {iconUrl && (
                    <BoxIconImg>
                        <ReactVivus
                            id={`svg-${id}`}
                            option={{
                                file: iconUrl,
                                type: 'oneByOne',
                                animTimingFunction: 'EASE',
                                duration: 250
                            }}
                        />
                    </BoxIconImg>
                )}
                <BoxIconContent>
                    {title && <BoxIcontitle {...titleStyles}>{truncateString(title, 18, false)}</BoxIcontitle>}
                    {desc && <BoxIconText {...textStyles}>{truncateString(desc, 90)}</BoxIconText>}
                    {/*<TeamMemberInfo {...infoStyle}>*/}
                    {/*    {name && <TeamMemberName {...nameStyle} >{name}</TeamMemberName>}*/}
                    {/*</TeamMemberInfo>*/}
                </BoxIconContent>
            </BoxIconInner>
        </BoxIconWrap>
    )
}

BoxIcon.propTypes = {
    heading: PropTypes.string,
    desc: PropTypes.string,
    path: PropTypes.string,
    boxStyles: PropTypes.object,
    titleStyles: PropTypes.object,
    textStyles: PropTypes.object,
    buttonStyles: PropTypes.object
}

BoxIcon.defaultProps = {
    buttonStyles: {
        varient: 'texted',
        fontWeight: 400,
        hover: "false",
        icondistance: "8px"
    },
    path: "/"
}

export default BoxIcon;
